import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import React from "react";
import moment from "moment";

class PeriodFilter extends React.Component {
    MIN_PERIOD = moment('2022-02-01').startOf("month");
    ACTUAL_PERIOD = moment().startOf("month");
    FULL_PERIOD = moment('0000-01-01');

    constructor(props) {
        super(props);

        this.state = {
            currentPeriod: moment(props.currentPeriod),
        };

        this.handleActualPeriod = this.handleActualPeriod.bind(this);
        this.handlePreviousPeriod = this.handlePreviousPeriod.bind(this);
        this.handleNextPeriod = this.handleNextPeriod.bind(this);
        this.handleAllPeriod = this.handleAllPeriod.bind(this);
    }

    handleActualPeriod() {
        this.setState((_) => {
            return {
                currentPeriod: this.ACTUAL_PERIOD.clone(),
            };
        }, () => this.props.onCurrentPeriodChanged(this.state.currentPeriod.format("YYYY-MM-DD")));
    }

    handlePreviousPeriod() {
        this.setState((state) => {
            return {
                currentPeriod: state.currentPeriod.subtract(1, 'month'),
            };
        }, () => this.props.onCurrentPeriodChanged(this.state.currentPeriod.format("YYYY-MM-DD")))
    }

    handleNextPeriod() {
        this.setState((state) => {
            return {
                currentPeriod: state.currentPeriod.add(1, 'month'),
            };
        }, () => this.props.onCurrentPeriodChanged(this.state.currentPeriod.format("YYYY-MM-DD")))
    }

    handleAllPeriod() {
        this.setState(() => {
            return {
                currentPeriod: this.FULL_PERIOD.clone(),
            };
        }, () => this.props.onCurrentPeriodChanged(this.state.currentPeriod.format("YYYY-MM-DD")))
    }

    render() {
        const isCurrentPeriodIsActual = this.state.currentPeriod.unix() === this.ACTUAL_PERIOD.unix();
        const isCurrentPeriodIsAll = this.state.currentPeriod.year() === 0;
        const currentPeriodLabel = isCurrentPeriodIsAll ? this.ACTUAL_PERIOD.format("MMMM'YY") : this.state.currentPeriod.format("MMMM'YY"); // октябрь'2022
        const isPrevDisabled = this.state.currentPeriod.unix() <= this.MIN_PERIOD.unix();
        const isNextDisabled = (this.state.currentPeriod.unix() >= this.ACTUAL_PERIOD.unix()) || isCurrentPeriodIsAll;

        return <ButtonToolbar>
            <ButtonGroup className="mr-2">
                <Button variant="secondary" active={isCurrentPeriodIsActual} onClick={this.handleActualPeriod}>сегодня</Button>
            </ButtonGroup>
            <ButtonGroup className="mr-2">
                <Button variant="secondary" disabled={isPrevDisabled} onClick={this.handlePreviousPeriod}>&laquo;</Button>
                <Button variant="secondary" onClick={this.handleActualPeriod}>{currentPeriodLabel}</Button>
                <Button variant="secondary" disabled={isNextDisabled} onClick={this.handleNextPeriod}>&raquo;</Button>
            </ButtonGroup>
            <ButtonGroup>
                <Button variant="secondary" active={isCurrentPeriodIsAll} onClick={this.handleAllPeriod}>весь период</Button>
            </ButtonGroup>
        </ButtonToolbar>;
    }
}

export default PeriodFilter;
