import axios from "axios";
import {logout} from "../../../components/Auth";

export const displayAdv = () => ({
    type: 'DISPLAY_ADV',
});

export const hideAdv = () => ({
    type: 'HIDE_ADV',
});

export const setAdvData = (data) => ({
    type: 'SET_ADV_DATA',
    data: data,
});

export const setDoctorsListData = (data) => ({
    type: 'SET_DOCTORS_LIST_DATA',
    data: data,
});

export const loadDoctorsList = (offset = 0) => {
    return (dispatch) => {
        let params = {};

        if (offset > 0) {
            params.offset = offset;
        }

        axios.get("/api/1/assistant/doctors", {
            params: params,
        })
            .then((response) => {
                dispatch(setDoctorsListData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const patientsInWorkListData = (data) => ({
    type: 'SET_PATIENTS_IN_WORK_LIST_DATA',
    data: data,
});

export const loadPatientsInWorkList = (offset = 0) => {
    return (dispatch) => {
        let params = {};

        if (offset > 0) {
            params.offset = offset;
        }

        axios.get("/api/1/assistant/patients-in-work", {
            params: params,
        })
            .then((response) => {
                dispatch(patientsInWorkListData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
}

export const doctorsPatientsListData = (data) => ({
    type: 'SET_DOCTORS_PATIENTS_LIST_DATA',
    data: data,
});

export const loadDoctorsPatientsList = (doctorID, offset = 0) => {
    return (dispatch) => {
        let params = {};

        if (offset > 0) {
            params.offset = offset;
        }

        axios.get("/api/1/assistant/doctors/" + doctorID + "/patients", {
            params: params,
        })
            .then((response) => {
                dispatch(doctorsPatientsListData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const editPatientData = (data) => ({
    type: 'SET_EDIT_PATIENT_DATA',
    data: data.patient,
});

export const loadEditPatientData = (patientID = 0) => {
    return (dispatch) => {
        axios.get("/api/1/assistant/patients/" + patientID)
            .then((response) => {
                dispatch(editPatientData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const currentPatientData = (data) => ({
    type: 'SET_CURRENT_PATIENT_DATA',
    data: data.patient,
});

export const loadCurrentPatientData = (patientID = 0) => {
    return (dispatch) => {
        axios.get("/api/1/assistant/patients/" + patientID)
            .then((response) => {
                dispatch(currentPatientData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const patientStudiesListData = (data) => ({
    type: 'SET_PATIENT_STUDIES_LIST_DATA',
    data: data,
});

export const loadPatientStudiesListData = (patientID = 0, offset = 0) => {
    return (dispatch) => {
        let params = {};

        if (offset > 0) {
            params.offset = offset;
        }

        axios.get("/api/1/assistant/patients/" + patientID + "/studies", {
            params: params,
        })
            .then((response) => {
                dispatch(patientStudiesListData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const studySharesListData = (data) => ({
    type: 'SET_STUDY_SHARES_LIST_DATA',
    data: data,
});

export const loadStudySharesListData = (studyID = 0) => {
    return (dispatch) => {
        axios.get("/api/1/assistant/studies/" + studyID + "/external-shares")
            .then((response) => {
                dispatch(studySharesListData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const dropdownDoctorsList = (data) => ({
    type: 'SET_DROPDOWN_DOCTORS_LIST',
    data: data.doctors,
});

export const loadDropdownDoctorsListData = () => {
    return (dispatch) => {
        axios.get("/api/1/assistant/dropdown/doctors")
            .then((response) => {
                dispatch(dropdownDoctorsList(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    };
};

export const dropdownDoctorPatientsList = (data) => ({
    type: 'SET_DROPDOWN_DOCTOR_PATIENTS_LIST',
    data: data.patients,
});

export const loadDropdownDoctorPatientsList = (doctorID) => {
    return (dispatch) => {
        axios.get("/api/1/assistant/dropdown/doctors/" + doctorID + "/patients")
            .then((response) => {
                dispatch(dropdownDoctorPatientsList(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const doctorData = (data) => ({
    type: 'SET_DOCTOR_DATA',
    data: data,
})

export const loadDoctorData = (doctorID) => {
    return (dispatch) => {
        axios.get("/api/1/assistant/doctors/" + doctorID)
            .then((response) => {
                dispatch(doctorData(response.data))
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.reload();
                }
            })
    };
};

export const commonStudiesListData = (data) => ({
    type: 'SET_COMMON_STUDIES_LIST_DATA',
    data: data,
});

export const loadCommonStudiesListData = (filterPeriod = '', sortedBy = 'createdAt.desc', offset = 0) => {
    return (dispatch) => {
        let params = {};

        if (filterPeriod) {
            params.filterPeriod = filterPeriod;
        }

        if (offset > 0) {
            params.offset = offset;
        }

        if (sortedBy) {
            params.sortedBy = sortedBy;
        }

        axios.get("/api/1/assistant/studies", {
            params: params,
        })
            .then((response) => {
                dispatch(commonStudiesListData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const settingsData = (data) => ({
    type: 'SET_SETTINGS_DATA',
    data: data,
});

export const loadSettingsData = () => {
    return (dispatch) => {
        axios.get("/api/1/assistant/settings")
            .then((response) => {
                dispatch(settingsData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const patientsForRestoreListData = (data) => ({
    type: 'SET_PATIENTS_FOR_RESTORE_LIST_DATA',
    data: data,
});

export const loadPatientsForRestoreList = (offset = 0) => {
    return (dispatch) => {
        let params = {};

        if (offset > 0) {
            params.offset = offset;
        }

        axios.get("/api/1/assistant/patients-for-restore", {
            params: params,
        })
            .then((response) => {
                dispatch(patientsForRestoreListData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
}

export const countersData = (data) => ({
    type: 'SET_COUNTERS_DATA',
    data: data,
});

export const loadCounters = () => {
    return (dispatch) => {
        axios.get("/api/1/assistant/counters")
            .then((response) => {
                dispatch(countersData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
}
