import {Alert, Button, Form, Image, Modal} from "react-bootstrap";
import React from "react";
import dislike from "./dislike.png";
import "./Pano.css";
import axios from "axios";
import {connect} from "react-redux";
import {logout} from "../../../../components/Auth";

class Pano extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            panoURL: props.panoURL,
            showModal: false,
            errorMsg: "",
            reason: "",
        };

        this.dislikeHandler = this.dislikeHandler.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.onChangeReason = this.onChangeReason.bind(this);
    }

    dislikeHandler() {
        axios.post("/api/1/assistant/studies/" + this.props.studyID + "/pano/dislike", {
            reason: this.state.reason,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                this.setState({
                    panoURL: "",
                })
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    showModal() {
        this.setState({
            showModal: true,
        })
    }

    hideModal() {
        this.setState({
            showModal: false,
        })
    }

    onChangeReason(e) {
        this.setState({
            reason: e.target.value,
        })
    }

    render() {
        if (!this.state.panoURL) {
            return <span/>
        }

        const modal = this.renderModal();
        const dislikeButton = this.renderDislikeButton();

        return <div className="pano">
            <Image src={this.state.panoURL} fluid/>
            {dislikeButton}
            {modal}
            &nbsp;
        </div>
    }

    renderDislikeButton() {
        if (!this.props.panoProtestEnabled) {
            return <span/>
        }

        return <Button className="pano-dislike" type="button" variant="light" size="sm"
                       onClick={this.showModal}>
            {<Image src={dislike} width="32"/>}
        </Button>
    }

    renderModal() {
        return <Modal show={this.state.showModal} onHide={this.hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Опротестование панорамы</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert show={this.state.errorMsg.length > 0}
                       key="pano-dislike"
                       variant="danger">{this.state.errorMsg}</Alert>
                <Form>
                    <Form.Group controlId="dislikeStudyPanoComment">
                        <Form.Label>Подскажите, почему вы хотите опротестовать панораму?</Form.Label>
                        <Form.Control as="textarea" rows={3} value={this.state.reason} onChange={this.onChangeReason}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={this.dislikeHandler}>
                    Опротестовать
                </Button>
                <Button variant="secondary" onClick={this.hideModal}>
                    Отмена
                </Button>
            </Modal.Footer>
        </Modal>
    }
}

const mapStateToProps = (state) => {
    return {
        panoProtestEnabled: state.settings.panoProtestEnabled,
    }
}

export default connect(mapStateToProps)(Pano);
