import React from "react";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import {Row} from "react-bootstrap";
import {logout} from "../../../components/Auth";

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: [],
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleSearch(query) {
        this.setState({
            isLoading: true,
        })

        axios.get("/api/1/doctor/patients/search", {
            params: {
                query: query,
            },
        })
            .then((response) => {
                const patients = response.data.patients.map((patient) => ({
                    name: patient.name,
                    path: '/doctor-area/patients/' + patient.patientID + '/studies',
                }));

                this.setState({
                    options: patients,
                    isLoading: false,
                });
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    };

    onChange(selected) {
        if (selected.length !== 1) {
            return;
        }

        window.location = selected[0].path;
    }

    render() {
        return (
            <AsyncTypeahead
                filterBy={() => true}
                id="doctor-search"
                isLoading={this.state.isLoading}
                labelKey="name"
                minLength={3}
                onChange={this.onChange}
                onSearch={this.handleSearch}
                options={this.state.options}
                placeholder="Поиск по базе"
                style={{
                    width: "100%",
                }}
                renderMenuItemChildren={(option) => (
                    <Row>
                        {option.name}
                    </Row>
                )}
            />
        );
    }
}

export default Search
