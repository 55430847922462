import React from "react";
import {connect} from "react-redux";
import {Card, Image} from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import {loadAdvDataList} from "../store/actions";
import utf8 from "utf8";
import base64 from "base-64";

class AdvBlock extends React.Component {
    componentDidMount() {
        this.props.loadAdvDataList();
    }

    render() {
        if (!this.props.show) {
            return '';
        }

        return this.renderBlock();
    }

    renderBlock() {
        if (!this.props.data.image && !this.props.data.text) {
            return '';
        }

        const text = this.renderPreviewText();
        const image = this.renderPreviewImage();

        return (
            <Card className="mt-3 mb-3" body>
                {image}
                {text}
            </Card>
        )
    }

    renderPreviewImage() {
        if (!this.props.data.image) {
            return '';
        }

        return (
            <Image src={this.props.data.image} fluid />
        )
    }

    renderPreviewText() {
        if (!this.props.data.text) {
            return '';
        }

        let text = this.props.data.text

        if (text.length > 0) {
            text = base64.decode(text)
            text = utf8.decode(text)
        }

        return (
            <ReactMarkdown children={text}/>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        show: state.advData !== null,
        data: state.advData,
    }
}

const mapDispatchToProps = {
    loadAdvDataList: loadAdvDataList,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvBlock);
