import React from "react";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {loadDropdownDoctorPatientsList, loadDropdownDoctorsListData} from "../store/actions";
import {connect} from "react-redux";
import axios from "axios";
import {Typeahead} from "react-bootstrap-typeahead";
import {logout} from "../../../components/Auth";

class CreateApplicationForm extends React.Component {
    constructor(props) {
        super(props);

        let doctorID = 0;
        if (props.doctorID) {
            doctorID = parseInt(props.doctorID);
            if (isNaN(doctorID)) {
                doctorID = 0
            }
        }

        let patientID = 0;
        if (props.patientID) {
            patientID = parseInt(props.patientID)
            if (isNaN(patientID)) {
                patientID = 0;
            }
        }

        this.state = {
            doctorID: doctorID,
            patientID: patientID,
            newPatientName: "",
            newPatientSex: "",
            newPatientBirthday: "",
            newPatientPhone: "",
            studyType: "",
            comment: "",
            selectedDoctor: [],
            selectedPatient: [],

            error: "",
            disableCreateBtn: false,
        };

        this.handleNewPatientName = this.handleNewPatientName.bind(this);
        this.handleNewPatientSex = this.handleNewPatientSex.bind(this);
        this.handleNewPatientBirthday = this.handleNewPatientBirthday.bind(this);
        this.handleNewPatientPhone = this.handleNewPatientPhone.bind(this);
        this.handleStudyType = this.handleStudyType.bind(this);
        this.handleComment = this.handleComment.bind(this);

        this.handleDoctorChange = this.handleDoctorChange.bind(this);
        this.handlePatientChange = this.handlePatientChange.bind(this);

        this.shouldCreatePatient = this.shouldCreatePatient.bind(this);
        this.valid = this.valid.bind(this);
        this.handleCreateStudy = this.handleCreateStudy.bind(this);
        this.createPatientWithStudy = this.createPatientWithStudy.bind(this);
        this.createStudy = this.createStudy.bind(this);
    }

    componentDidMount() {
        this.props.loadDoctorsListData();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (!prevProps.doctorID && this.props.doctorID) {
            let doctorID = parseInt(this.props.doctorID);
            if (isNaN(doctorID)) {
                doctorID = 0
            }

            this.setState({
                doctorID: doctorID,
            }, this.selectDoctor);
        }

        if (!prevProps.patientID && this.props.patientID) {
            let patientID = parseInt(this.props.patientID);
            if (isNaN(patientID)) {
                patientID = 0;
            }

            this.setState({
                patientID: patientID,
            }, this.selectPatient);
        }

        if (this.state.doctorID > 0 && !this.state.selectedDoctor.length) {
            this.selectDoctor();
        }

        if (this.state.patientID > 0 && !this.state.selectedPatient.length) {
            this.selectPatient();
        }
    }

    selectDoctor() {
        const filteredDoctors = this.props.doctorsList.filter((el) => el.doctorID === this.state.doctorID);

        if (filteredDoctors.length > 0) {
            this.setState({
                selectedDoctor: [filteredDoctors[0]]
            });

            this.props.loadDoctorPatientsList(this.state.doctorID);
        }
    }

    selectPatient() {
        const filteredPatients = this.props.doctorPatientsList.filter((el) => el.patientID === this.state.patientID);

        if (filteredPatients.length > 0) {
            this.setState({
                selectedPatient: [filteredPatients[0]]
            });
        }
    }

    handleNewPatientName(e) {
        this.setState({
            newPatientName: e.target.value,
        })
    }

    handleNewPatientSex(e) {
        this.setState({
            newPatientSex: e.target.value,
        })
    }

    handleNewPatientBirthday(e) {
        this.setState({
            newPatientBirthday: e.target.value,
        })
    }

    handleNewPatientPhone(e) {
        this.setState({
            newPatientPhone: e.target.value,
        })
    }

    handleStudyType(e) {
        this.setState({
            studyType: e.target.value,
        })
    }

    handleComment(e) {
        this.setState({
            comment: e.target.value,
        })
    }

    shouldCreatePatient() {
        return this.state.patientID === 0
    }

    valid() {
        this.setState({
            error: "",
        })

        if (this.state.doctorID <= 0) {
            this.setState(
                {
                    error: "Выберите врача",
                }
            )
            return false;
        }

        if (this.shouldCreatePatient()) {
            // validate create patient
            if (!this.state.newPatientName) {
                this.setState(
                    {
                        error: "Введите ФИО нового пациента или выберите существующего",
                    }
                )
                return false;
            }

            if (!this.state.newPatientSex) {
                this.setState(
                    {
                        error: "Выберите пол нового пациента",
                    }
                )
                return false;
            }

            if (!this.state.newPatientBirthday) {
                this.setState(
                    {
                        error: "Выберите дату рождения нового пациента",
                    }
                )
                return false;
            }
        } else {
            // validate select patient
            if (this.state.patientID <= 0) {
                this.setState(
                    {
                        error: "Выберите существующего пациента из списка",
                    }
                )
                return false;
            }
        }

        if (!this.state.studyType) {
            this.setState(
                {
                    error: "Выберите тип исследования",
                }
            )
            return false;
        }

        return true;
    }

    handleCreateStudy(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!this.valid()) {
            return
        }

        this.setState({disableCreateBtn: true});

        if (this.shouldCreatePatient()) {
            this.createPatientWithStudy();
            return
        }

        this.createStudy(this.state.patientID);
    }

    createPatientWithStudy() {
        const path = "/api/1/assistant/doctors/" + this.state.doctorID + "/patients"

        axios.post(path, {
            name: this.state.newPatientName,
            sex: this.state.newPatientSex,
            birthday: this.state.newPatientBirthday,
            phone: this.state.newPatientPhone,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            this.createStudy(response.data.patientID)
        }).catch((error) => {
            if (!error.response) {
                console.log(error);
                return
            }

            this.setState({
                error: error.response.data.error,
                disableCreateBtn: false,
            })

            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        })
    }

    createStudy(patientID) {
        const path = "/api/1/assistant/patients/" + patientID + "/studies"

        axios.post(path, {
            studyType: this.state.studyType,
            comment: this.state.comment,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            window.location.href = '/assistant-area/doctors/' + this.state.doctorID + '/patients/' + patientID + '/studies';
            this.setState({disableCreateBtn: false});
        }).catch((error) => {
            if (!error.response) {
                console.log(error);
                return
            }

            this.setState({
                error: error.response.data.error,
                disableCreateBtn: false,
            })

            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        })
    }

    render() {
        const doctorsDropdown = this.renderDoctorsDropdown();
        const patientsDropdown = this.renderPatientsDropdown();
        const createPatientFieldsDisabled = this.state.patientID > 0;

        return (
            <Form>
                <h3>Выберите клиента ДЦ (врач или клиника)</h3>
                <Form.Group>
                    {doctorsDropdown}
                </Form.Group>
                <Row>
                    <Col md="5">
                        <h3>Выберите пациента из списка</h3>
                        <Form.Group>
                            {patientsDropdown}
                        </Form.Group>
                    </Col>
                    <Col md="2"><p className="text-center mt-3">или</p></Col>
                    <Col md="5">
                        <h3>Создание карточки пациента</h3>
                        <Form.Group>
                            <Form.Label>ФИО пациента</Form.Label>
                            <Form.Control value={this.state.newPatientName} onChange={this.handleNewPatientName}
                                          placeholder="например Иванов Петр Анатольевич"
                                          disabled={createPatientFieldsDisabled} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Пол пациента</Form.Label>
                            <div key="inline-radio-sex" className="mb-3" id="sex">
                                <Form.Check inline onChange={this.handleNewPatientSex} label="муж." name="sex"
                                            type="radio" value="male" id="sexMale"
                                            checked={this.state.newPatientSex === "male"}
                                            disabled={createPatientFieldsDisabled} />
                                <Form.Check inline onChange={this.handleNewPatientSex} label="жен." name="sex"
                                            type="radio" value="female" id="sexFemale"
                                            checked={this.state.newPatientSex === "female"}
                                            disabled={createPatientFieldsDisabled} />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Дата рождения пациента</Form.Label>
                            <Form.Control value={this.state.newPatientBirthday}
                                          onChange={this.handleNewPatientBirthday} type="date"
                                          disabled={createPatientFieldsDisabled} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Телефон пациента</Form.Label>
                            <Form.Control value={this.state.newPatientPhone} onChange={this.handleNewPatientPhone}
                                          placeholder="например, +79991234567"
                                          disabled={createPatientFieldsDisabled} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <h3>Выберите тип проводимого исследования</h3>
                        <Form.Group controlId="createStudyType">
                            <div key="inline-radio-study_type" className="mb-3">
                                <Form.Check inline onChange={this.handleStudyType} label="Рентгенография (2D)"
                                            name="study_type" type="radio" value="rg"
                                            checked={this.state.studyType === "rg"}
                                            id="study_type_rg"/>
                                <Form.Check inline onChange={this.handleStudyType}
                                            label="Конусно-лучевая компьютерная томография (3D)"
                                            name="study_type" type="radio" value="cbct"
                                            checked={this.state.studyType === "cbct"}
                                            id="study_type_cbct"/>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group>
                    <Form.Label>Оставьте комментарий к исследованию</Form.Label>
                    <Form.Control value={this.state.comment} onChange={this.handleComment} as="textarea" rows="5"/>
                </Form.Group>
                <Alert variant="danger" show={!!this.state.error}>{this.state.error}</Alert>
                <Form.Group className="text-right">
                    <Button type="submit" variant="primary" disabled={this.state.disableCreateBtn}
                            onClick={this.handleCreateStudy}>Создать
                        исследование</Button>
                </Form.Group>
            </Form>
        )
    }

    handleDoctorChange(options) {
        if (options.length < 1) {
            this.setState({
                doctorID: 0,
                patientID: 0,
                selectedDoctor: [],
            })
            return;
        }

        const doctorID = options[0].doctorID;

        this.setState({
            doctorID: parseInt(doctorID),
            selectedDoctor: [options[0]],
            selectedPatient: [],
            patientID: 0, // hack for select first option
        })

        this.props.loadDoctorPatientsList(doctorID);
    }

    renderDoctorsDropdown() {
        return (
            <Typeahead
                id="choose-doctor"
                labelKey="name"
                onChange={this.handleDoctorChange}
                options={this.props.doctorsList}
                placeholder="выберите клиента"
                selected={this.state.selectedDoctor}
            />
        )
    }

    handlePatientChange(options) {
        let patientID = 0;
        let selectedPatient = [];

        if (options.length === 1) {
            patientID = options[0].patientID;
            selectedPatient = [options[0]];
        }

        this.setState({
            patientID: patientID,
            selectedPatient: selectedPatient,
        })
    }

    renderPatientsDropdown() {
        return (
            <Typeahead
                id="choose-patient"
                labelKey="name"
                onChange={this.handlePatientChange}
                options={this.props.doctorPatientsList}
                placeholder="выберите пациента"
                selected={this.state.selectedPatient}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        doctorsList: state.dropdownDoctorsList,
        doctorPatientsList: state.dropdownDoctorPatientsList,
    }
}

const mapDispatchToProps = {
    loadDoctorsListData: loadDropdownDoctorsListData,
    loadDoctorPatientsList: loadDropdownDoctorPatientsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateApplicationForm);
