import React from "react";
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import axios from "axios";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import BrowserCompatibilityAlert from "./BrowserCompatibilityAlert";
import {Link} from "react-router-dom";

class AuthArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validated: false,
            email: '',
            password: '',
            error: '',
            showPassword: false,
            disableSignInBtn: false,
        };

        this.setValidated = this.setValidated.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleTogglePassword = this.handleTogglePassword.bind(this);
    }

    setValidated(validated) {
        this.setState({validated: validated});
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return
        }

        this.setValidated(true);

        let credentials = Buffer.from(this.state.email + ':' + this.state.password).toString('base64');

        this.setState({disableSignInBtn: true});

        axios.post('/api/1/auth/token', {
            'grant_type': 'client_credentials',
        }, {
            headers: {
                'Authorization': 'Basic ' + credentials,
            }
        }).then((response) => {
            this.setState({disableSignInBtn: false});
            this.props.setAuthData(response.data);
        })
            .catch((error) => {
                let err_msg = 'Произошла неизвестная ошибка';

                console.log('auth/token', error);

                if (error.hasOwnProperty('response') && error.response.status !== 200) {
                    switch (error.response.status) {
                        case 500:
                            err_msg = 'Произошла ошибка сервера. Повторите запрос';
                            break;
                        case 400:
                            err_msg = 'Невалидный E-mail';
                            if (error.response.data.error !== 'invalid_request') {
                                err_msg = 'Невалидный запрос: ' + error.response.data.error;
                            }
                            break;
                        case 403:
                            err_msg = 'Неверный E-mail или пароль';
                            if (error.response.data.error !== 'unauthorized_client') {
                                err_msg = 'Доступ запрещен: ' + error.response.data.error;
                            }
                            break;
                        default:
                            err_msg = 'Неизвестный код ошибки: ' + error.response.status;
                            break;
                    }
                }

                this.setState({error: err_msg, disableSignInBtn: false});
            })
    }

    handleChangeEmail(event) {
        this.setState({email: event.target.value})
    }

    handleChangePassword(event) {
        this.setState({password: event.target.value})
    }

    handleTogglePassword(event) {
        this.setState((prevState, props) => {
            return {
                showPassword: !prevState.showPassword,
            }
        })
    }

    render() {
        let error = '';
        if (this.state.error) {
            error = <p className="text-danger">{this.state.error}</p>
        }

        let showPasswordIcon = <FaEye style={{'cursor': 'pointer'}} onClick={this.handleTogglePassword}/>;
        let passwordType = "password";
        if (this.state.showPassword) {
            showPasswordIcon = <FaEyeSlash style={{'cursor': 'pointer'}} onClick={this.handleTogglePassword}/>;
            passwordType = "text";
        }

        return (
            <Container>
                <BrowserCompatibilityAlert/>
                <Row>
                    <Col md={{offset: 3, span: 6}}>
                        <h1>Вход в личный кабинет</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={{offset: 3, span: 6}}>
                        {error}
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="authFormEmail">
                                <Form.Label>E-mail:</Form.Label>
                                <Form.Control onChange={this.handleChangeEmail} required type="email"
                                              placeholder="username@example.com"/>
                                <Form.Text className="text-muted">
                                    Адрес электронной почты
                                </Form.Text>
                            </Form.Group>

                            <label htmlFor="authFormPassword">Пароль:</label>
                            <InputGroup className="mb-3">
                                <FormControl onChange={this.handleChangePassword} required type={passwordType}
                                             minLength="8" placeholder="secret"
                                             id="authFormPassword"/>
                                <InputGroup.Append>
                                    <InputGroup.Text>
                                        {showPasswordIcon}
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>

                            <Button variant="primary" type="submit" disabled={this.state.disableSignInBtn}>
                                Вход
                            </Button>
                            <Button variant="link" as={Link} to="/forgot-password" className="ml-2">Забыли пароль?</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default AuthArea;
