import {Button, Card, Col, Image, Row} from "react-bootstrap";
import React from "react";
import moment from "moment";
import axios from "axios";
import saveAs from "file-saver"
import RemoteViewerModal from "../../../components/RemoteViewerModal";
import {
    mapStatusToBadge,
    mapTypeToString,
    STUDY_STATUS_ARCHIVED,
    STUDY_STATUS_DONE,
    STUDY_TYPE_RG
} from "../../../components/Study";
import {logout} from "../../../components/Auth";
import ThreeDSegmentationModal from "./3DSegmentationModal";

class Study extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            study: props.study,
        }

        this.refreshStudy = this.refreshStudy.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handlerRestore = this.handlerRestore.bind(this);
    }

    render() {
        const header = this.renderHeader();
        const pano = this.renderPano();
        const comment = this.renderComment();
        const downloadButton = this.renderDownloadButton();
        const restoreButton = this.renderRestoreButton();
        const remoteViewerButton = this.renderRemoteViewerButton();
        const threeDSegmentationButton = this.render3DSegmentationButton();

        let uploadMaxAge = this.props.uploadMaxAge;
        if (uploadMaxAge === '8760h0m0s') {
            uploadMaxAge = '1 год';
        }

        return (
            <Card className="mb-2">
                <Card.Header>
                    {header}
                </Card.Header>
                <Card.Body>
                    {pano}
                    {comment}
                    <Row>
                        <Col md={12}>
                            {downloadButton}
                            {remoteViewerButton}
                            {restoreButton}
                            {threeDSegmentationButton}
                        </Col>
                        <Col md={6}>
                            <small class="text-muted">Срок хранения: {uploadMaxAge}</small>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    renderHeader() {
        const study = this.state.study;
        const status = mapStatusToBadge[study.status];
        const date = moment(study.createdAt).format("DD.MM.YYYY");
        const type = mapTypeToString[study.type];
        const restored = study.restored ? '(восстановлена)' : '';

        return (
            <>
                {status} {date} {type} {restored}
            </>
        );
    }

    renderPano() {
        const study = this.state.study;

        if (!study.panoURL || study.panoURL.length === 0) {
            return <span/>
        }

        return <>
            <Image src={study.panoURL} fluid/>&nbsp;
        </>
    }

    renderComment() {
        const study = this.state.study;
        let comment = <i>Отсутствует</i>

        if (study.comment !== '') {
            comment = study.comment;
        }

        return (
            <>
                <p>Комментарий:</p>
                <p>{comment}</p>
            </>
        );
    }

    handleDownload() {
        axios.get("/api/1/doctor/patients/" + this.state.study.patientID + "/studies/" + this.state.study.studyID + "/download", {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            const url = new URL(response.data.presignedURL);
            const pathParts = url.pathname.split("/");
            const filename = pathParts[pathParts.length - 1];

            saveAs(response.data.presignedURL, filename)
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    renderDownloadButton() {
        const study = this.state.study;
        if (study.status !== STUDY_STATUS_DONE) {
            return <span/>
        }

        return (
            <>
                <Button variant="primary" onClick={this.handleDownload}>Скачать</Button>
            </>
        );
    }

    renderRemoteViewerButton() {
        const study = this.state.study;
        if (study.status !== STUDY_STATUS_DONE) {
            return <span/>
        }

        if (!this.props.remoteViewerEnabled) {
            return <span/>
        }

        if (study.type === STUDY_TYPE_RG) {
            return <span/>
        }

        return <>
            &nbsp;
            <RemoteViewerModal study={this.state.study}/>
        </>
    }

    refreshStudy() {
        axios.get("/api/1/doctor/patients/" + this.state.study.patientID + "/studies/" + this.state.study.studyID, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            this.setState({
                study: response.data.study,
            })
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    handlerRestore() {
        axios.put("/api/1/doctor/patients/" + this.state.study.patientID + "/studies/" + this.state.study.studyID + "/restore", {}, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(this.refreshStudy)
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    renderRestoreButton() {
        if (this.props.shared) {
            return <span/>
        }

        const study = this.state.study;
        if (study.status !== STUDY_STATUS_ARCHIVED) {
            return <span/>
        }

        return (
            <Button variant="secondary" onClick={this.handlerRestore}>Восстановить данные</Button>
        );
    }

    render3DSegmentationButton() {
        const study = this.state.study;
        if (study.status !== STUDY_STATUS_DONE) {
            return <span/>
        }

        if (!this.props.threeDSegEnabled) {
            return <span/>
        }

        if (study.type === STUDY_TYPE_RG) {
            return <span/>
        }

        return <>
            &nbsp;
            <ThreeDSegmentationModal study={this.state.study}/>
        </>
    }
}

export default Study;
