import React from "react";
import "./TableSortedTh.css";

class TableSortedTh extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            direction: this.props.direction ?? null,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.direction !== this.props.direction) {
            this.setState({direction: this.props.direction});
        }
    }

    handleClick() {
        let nextDirection = this.state.direction;

        if (this.state.direction === null) {
            nextDirection = 'asc';
        }

        if (this.state.direction === 'asc') {
            nextDirection = 'desc';
        }

        if (this.state.direction === 'desc') {
            nextDirection = 'asc';
        }

        this.setState({direction: nextDirection}, () => {
            if (!this.props.onColumnChange) {
                return;
            }

            this.props.onColumnChange(this.props.name ?? null, nextDirection);
        });
    }

    render() {
        const sortDirection = this.renderSortDirection();

        return <th className="th-sort" width={this.props.width} onClick={this.handleClick}>
            <>
                {this.props.children}
                {sortDirection}
            </>
        </th>;
    }

    renderSortDirection() {
        if (!this.state.direction) {
            return <></>;
        }

        return <span className={this.state.direction === 'asc' ? 'up' : ''} />;
    }
}

export default TableSortedTh;
