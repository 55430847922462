import {connect} from "react-redux";
import {loadPatientsList} from "../store/actions";
import PatientsList from "./PatientsList";

class DoctorPatientsList extends PatientsList {}

const mapStateToProps = (state) => {
    return {
        patientsList: state.patientsList,
    }
}

const mapDispatchToProps = {
    loadPatientsList: loadPatientsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorPatientsList)
