import React from "react";
import {Alert, Button, Col, Form, Image, Row} from "react-bootstrap";
import SimpleMDE from "react-simplemde-editor";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {displayAdv, hideAdv, setAdvData} from "../store/actions";
import axios from "axios";
import utf8 from "utf8";
import base64 from "base-64";
import ReactMarkdown from "react-markdown";
import {logout} from "../../../components/Auth";

const editorOptions = {
    spellChecker: false,
    toolbar: [
        'bold',
        'italic',
        'unordered-list',
        'ordered-list',
        'link',
    ],
};

class UpdateBannerForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            advText: null,
            advImage: null,
            isSaved: false,
        };

        this.onChangeAdvText = this.onChangeAdvText.bind(this);
        this.loadAdvData = this.loadAdvData.bind(this);
        this.renderEditor = this.renderEditor.bind(this);
        this.renderPreview = this.renderPreview.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.renderPreviewImage = this.renderPreviewImage.bind(this);
        this.renderPreviewText = this.renderPreviewText.bind(this);
        this.saveAdvData = this.saveAdvData.bind(this);
        this.clearImage = this.clearImage.bind(this);
    }

    componentDidMount() {
        this.props.displayAdv();
        this.loadAdvData();
    }

    componentWillUnmount() {
        this.props.hideAdv();
    }

    loadAdvData() {
        axios.get("/api/1/assistant/adv")
            .then((response) => {
                let text = response.data.text;
                if (text.length > 0) {
                    text = base64.decode(text)
                    text = utf8.decode(text)
                }

                this.setState({
                    advText: text,
                    advImage: response.data.image,
                });
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }

    onFileChange(e) {
        if (e.target.files.length < 1) {
            return;
        }

        const file = e.target.files[0];

        const availableTypes = ['image/png', 'image/jpeg'];

        if (!availableTypes.includes(file.type)) {
            this.setState({
                advImage: null,
            })
            return;
        }

        let reader = new FileReader();
        reader.addEventListener('load', (event) => {
            this.setState({
                advImage: event.target.result,
            })
        })
        reader.readAsDataURL(file);
    }

    saveAdvData() {
        let text = this.state.advText;
        let image = this.state.advImage;

        if (text) {
            text = utf8.encode(text);
            text = base64.encode(text);
        }

        axios.post("/api/1/assistant/adv", {
            text: text,
            image: image,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(() => {
                this.setState({
                    isSaved: true,
                })

                if (this.isSavedTimeout) {
                    clearTimeout(this.isSavedTimeout);
                }

                this.isSavedTimeout = setTimeout(() => {
                    this.setState({
                        isSaved: false,
                    })
                }, 3000);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }

    clearImage() {
        this.setState({
            advImage: null,
        })
    }

    render() {
        const editor = this.renderEditor();
        const preview = this.renderPreview();

        return (
            <>
                <h3>Настройка баннера в личном кабинете клиентов ДЦ</h3>
                <p>Форма ниже позволяет отредактировать информационный баннер в личном кабинете клиентов.</p>
                <Row>
                    <Col md={6}>
                        <h4>Редактор</h4>
                        <Form.Group>
                            <Form.Label>Выберите картинку</Form.Label>
                            <Row>
                                <Col md={10}>
                                    <Form.File
                                        id="adv-image-file"
                                        label="Картинка в рекламном баннере"
                                        onChange={this.onFileChange}
                                        custom
                                    />
                                </Col>
                                <Col md={1}>
                                    <Button onClick={this.clearImage}>X</Button>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Редактировать текст</Form.Label>
                            <div>
                                {editor}
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Button variant="primary" onClick={this.saveAdvData}>Сохранить</Button>
                            <br/>
                            <br/>
                            <Alert key="success" variant="success" show={this.state.isSaved}>
                                Сохранено
                            </Alert>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <h4>Предпросмотр</h4>
                        {preview}
                    </Col>
                </Row>
            </>
        )
    }

    onChangeAdvText(text) {
        this.setState({
            advText: text,
        })
    }

    renderEditor() {
        if (this.state.advText === null) {
            return 'Загрузка...';
        }

        return (
            <SimpleMDE value={this.state.advText}
                       onChange={this.onChangeAdvText}
                       options={editorOptions}/>
        )
    }

    renderPreview() {
        const text = this.renderPreviewText();
        const image = this.renderPreviewImage();

        return (
            <>
                {image}
                {text}
            </>
        )
    }

    renderPreviewImage() {
        if (!this.state.advImage) {
            return '';
        }

        return (
            <Image src={this.state.advImage} fluid/>
        )
    }

    renderPreviewText() {
        if (!this.state.advText) {
            return '';
        }

        return (
            <ReactMarkdown children={this.state.advText}/>
        )
    }
}

export default connect(null, (dispatch) => {
    return {
        displayAdv: bindActionCreators(displayAdv, dispatch),
        hideAdv: bindActionCreators(hideAdv, dispatch),
        setAdvData: bindActionCreators(setAdvData, dispatch),
    };
})(UpdateBannerForm);
