import React from "react";
import {Col, Form, FormControl, InputGroup, Media} from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import {logout} from "../../../../components/Auth";

class MessageList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogID: -1,
            messageText: "",
        };

        this.renderMessage = this.renderMessage.bind(this);
        this.renderMessageTime = this.renderMessageTime.bind(this);
        this.renderMessages = this.renderMessages.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.handleSendMessage = this.handleSendMessage.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const chatBoxArr = document.getElementsByClassName('chat-box');

        if (chatBoxArr.length === 0) {
            return;
        }

        const chatBox = chatBoxArr[0];

        chatBox.scrollTop = chatBox.scrollHeight;
    }

    handleSendMessage(e) {
        e.preventDefault();

        axios.post("/api/1/assistant/messenger/dialogs/" + this.props.dialogID + "/messages/send", {
            text: this.state.messageText,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                this.setState({messageText: ""});
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    render() {
        if (this.props.dialogID === -1) {
            return (
                <Col md="7" className="messenger-choose-dialog">
                    <p className="text-muted text-center">Выберите диалог слева.</p>
                </Col>
            );
        }

        const messages = this.renderMessages();
        const form = this.renderForm();

        return (
            <Col md="7" className="px-0">
                <div className="pl-2 pr-4 pt-4 chat-box bg-white">
                    {messages}
                </div>

                {form}
            </Col>
        );
    }

    renderForm() {
        return (
            <Form className="bg-light" onSubmit={this.handleSendMessage}>
                <InputGroup>
                    <FormControl placeholder="Введите сообщение..."
                                 value={this.state.messageText}
                                 onChange={(e) => this.setState({messageText: e.target.value})}
                                 className="rounded-0 border-0 py-4 bg-light"/>
                    <InputGroup.Append>
                        <button id="button-addon2" type="submit" className="btn btn-link">
                            <img
                                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNTM1LjUgNTM1LjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUzNS41IDUzNS41OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGcgaWQ9InNlbmQiPgoJCTxwb2x5Z29uIHBvaW50cz0iMCw0OTcuMjUgNTM1LjUsMjY3Ljc1IDAsMzguMjUgMCwyMTYuNzUgMzgyLjUsMjY3Ljc1IDAsMzE4Ljc1ICAgIiBmaWxsPSIjY2JjYmNiIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="
                                style={{"height": "25px"}} alt="Отправить"/>
                        </button>
                    </InputGroup.Append>
                </InputGroup>
            </Form>
        )
    }

    renderMessages() {
        if (this.props.messages.length === 0) {
            return (
                <p className="text-muted text-center">Напишите первое сообщение.</p>
            )
        }

        return this.props.messages.map((message) => {
            return this.renderMessage(message);
        })
    }

    renderMessage(message) {
        if (message.isAnswer) {
            return (
                <Media key={message.messageID} className="w-50 ml-auto mb-3">
                    <Media.Body>
                        <div className="bg-primary rounded py-2 px-3 mb-2">
                            <p className="text-small mb-0 text-white">
                                {message.text}
                            </p>
                        </div>
                        <p className="small text-muted">
                            отправлено {this.renderMessageTime(message.createdAt)}
                        </p>
                    </Media.Body>
                </Media>
            );
        }

        return (
            <Media key={message.messageID} className="w-50 mb-3">
                <Media.Body className="ml-3">
                    <div className="bg-light rounded py-2 px-3 mb-2">
                        <p className="text-small mb-0 text-muted">
                            {message.text}
                        </p>
                    </div>
                    <p className="small text-muted">
                        отправлено {this.renderMessageTime(message.createdAt)}
                    </p>
                </Media.Body>
            </Media>
        );
    }

    renderMessageTime(createdAt) {
        return moment(createdAt).format("lll");
    }
}

export default MessageList;
