import React from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import axios from "axios";
import {logout} from "../../../components/Auth";

class EditStudyModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            error: null,
            study: props.study,
        };

        this.setShow = this.setShow.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.renderButton = this.renderButton.bind(this);
        this.onChangeComment = this.onChangeComment.bind(this);
    }

    setShow(isOpened) {
        this.setState({opened: isOpened})
    }

    handleShow() {
        this.setShow(true)
    }

    handleClose() {
        this.setShow(false)
    }

    handleSave() {
        axios.patch("/api/1/assistant/studies/" + this.props.study.studyID, {
            "comment": this.state.study.comment,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then(() => {
            this.props.refreshStudy();
            this.handleClose();
        }).catch((error) => {
            if (!error.response) {
                console.log(error);
                return
            }

            this.setState({
                error: error.response.data.error,
            })

            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    onChangeComment(e) {
        this.setState(
            {
                study: {
                    comment: e.target.value,
                }
            }
        )
    }

    render() {
        const button = this.renderButton();

        if (!this.state.opened) {
            return button;
        }

        return <>
            {button}

            <Modal show={this.state.opened} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Редактирование исследования</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="editStudyComment">
                            <Form.Label>Комментарий</Form.Label>
                            <Form.Control as="textarea" rows={3} value={this.state.study.comment} onChange={this.onChangeComment} />
                        </Form.Group>
                    </Form>
                    <Alert variant="danger" show={!!this.state.error}>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleSave}>
                        Сохранить
                    </Button>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>
        </>;
    }

    renderButton() {
        return <Button variant="primary" onClick={this.handleShow} title="Редактировать">Р</Button>
    }
}

export default EditStudyModal;
