import React from "react";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import {Col, Row} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {logout} from "../../../components/Auth";

const TYPE_PATIENT = 'пациент';
const TYPE_DOCTOR = 'доктор или клиника';

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: [],
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleSearch(query) {
        this.setState({
            isLoading: true,
        })

        axios.get("/api/1/assistant/search", {
            params: {
                query: query,
            },
        })
            .then((response) => {
                const patients = response.data.patients.map((patient) => ({
                    type: TYPE_PATIENT,
                    name: patient.name,
                    path: '/assistant-area/doctors/' + patient.doctorID + '/patients/' + patient.patientID + '/studies',
                }));

                const doctors = response.data.doctors.map((doctor) => ({
                    type: TYPE_DOCTOR,
                    name: doctor.name,
                    path: '/assistant-area/doctors/' + doctor.doctorID + '/patients',
                }));

                this.setState({
                    options: patients.concat(doctors),
                    isLoading: false,
                });
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    };

    onChange(selected) {
        if (selected.length !== 1) {
            return;
        }

        window.location = selected[0].path;
    }

    render() {
        return (
            <AsyncTypeahead
                filterBy={() => true}
                id="assistant-search"
                isLoading={this.state.isLoading}
                labelKey="name"
                minLength={3}
                onChange={this.onChange}
                onSearch={this.handleSearch}
                options={this.state.options}
                placeholder="Поиск по базе"
                style={{
                    width: "100%",
                }}
                renderMenuItemChildren={(option) => (
                    <Row>
                        <Col md={9}>{option.name}</Col>
                        <Col md={1}
                             className="text-right">{option.type === TYPE_PATIENT ? 'пациент' : 'доктор или клиника'}</Col>
                    </Row>
                )}
            />
        );
    }
}

export default withRouter(Search);
