import React from "react";
import {Alert, Button, Modal} from "react-bootstrap";
import axios from "axios";
import {logout} from "../../../components/Auth";

class DeleteStudyModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            error: null,
            study: props.study,
        };

        this.setShow = this.setShow.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.renderButton = this.renderButton.bind(this);
    }

    setShow(isOpened) {
        this.setState({opened: isOpened})
    }

    handleShow() {
        this.setShow(true)
    }

    handleClose() {
        this.setShow(false)
    }

    handleDelete() {
        axios.delete("/api/1/assistant/studies/" + this.props.study.studyID, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then(() => {
            this.handleClose();
            this.props.deleteStudy();
        }).catch((error) => {
            if (!error.response) {
                console.log(error);
                return
            }

            this.setState({
                error: error.response.data.error,
            })

            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    render() {
        if (!this.state.study.assistantCanDelete) {
            return <span />;
        }

        const button = this.renderButton();

        if (!this.state.opened) {
            return button;
        }

        return <>
            {button}

            <Modal show={this.state.opened} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Удаление исследования</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="danger" show={!!this.state.error}>{this.state.error}</Alert>
                    <p>Вы действительно хотите удалить исследование?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.handleDelete}>
                        Удалить
                    </Button>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>
        </>;
    }

    renderButton() {
        return <>
            &nbsp;
            <Button variant="danger" onClick={this.handleShow} title="Удалить">У</Button>
        </>
    }
}

export default DeleteStudyModal;
