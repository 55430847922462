import {Badge} from "react-bootstrap";
import React from "react";

export const STUDIES_LIST_LIMIT = 30;

export const mapTypeToString = {
    'rg': "Рентгенография",
    'cbct': "КЛКТ",
};

export const STUDY_TYPE_RG = 'rg';

export const STUDY_TYPE_CBCT = 'cbct';

export const STUDY_STATUS_IN_WORK = 'in-work';

export const STUDY_STATUS_DONE = 'done';

export const STUDY_STATUS_ARCHIVED = 'archived';

export const mapStatusToBadge = {
    [STUDY_STATUS_IN_WORK]: <Badge variant="primary">В работе</Badge>,
    [STUDY_STATUS_DONE]: <Badge variant="success">Исполнено</Badge>,
    [STUDY_STATUS_ARCHIVED]: <Badge variant="secondary">Архивировано</Badge>,
};
