import {Button, Modal} from "react-bootstrap";
import React from "react";
import "./RemoteViewerModal.css";
import VNCViewer from "./VNCViewer";

class RemoteViewerModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
        };

        this.setShow = this.setShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.renderButton = this.renderButton.bind(this);
    }

    setShow(isOpened) {
        this.setState({opened: isOpened})
    }

    handleClose() {
        this.setShow(false)
    }

    handleShow() {
        this.setShow(true)
    }

    render() {
        if (!this.state.opened) {
            return this.renderButton()
        }

        return (
            <>
                {this.renderButton()}

                <Modal dialogClassName="remote-viewer-modal-dialog"
                       show={this.state.opened}
                       onHide={this.handleClose}
                       backdrop="static"
                       keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Онлайн просмотр исследования</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <VNCViewer onDisconnect={this.handleClose}
                                   opened={this.state.opened}
                                   study={this.props.study}
                                   shareToken={this.props.shareToken}
                                   isAssistant={this.props.isAssistant ?? false}
                                   isExternal={this.props.isExternal ?? false}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleClose}>
                            Готово
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    renderButton() {
        return <Button variant="info"
                       onClick={this.handleShow} >
            Онлайн-просмотр
        </Button>
    }
}

export default RemoteViewerModal;
