import {Button, Card, Col, Row} from "react-bootstrap";
import React from "react";
import moment from "moment";
import UploadZone from "./UploadZone";
import axios from "axios";
import saveAs from "file-saver"
import RemoteViewerModal from "../../../components/RemoteViewerModal";
import {
    mapStatusToBadge,
    mapTypeToString,
    STUDY_STATUS_ARCHIVED,
    STUDY_STATUS_DONE,
    STUDY_STATUS_IN_WORK,
    STUDY_TYPE_RG
} from "../../../components/Study";
import EditStudyModal from "./EditStudyModal";
import DeleteStudyModal from "./DeleteStudyModal";
import Pano from "./Pano/Pano";
import CopyStudyModal from "./CopyStudyModal";
import ShareStudyModal from "./ShareStudyModal";
import {logout} from "../../../components/Auth";

class Study extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            study: props.study,
            deleted: false,
            reportBtnDisabled: false,
        }

        this.refreshStudy = this.refreshStudy.bind(this);
        this.deleteStudy = this.deleteStudy.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handlerRestore = this.handlerRestore.bind(this);
        this.handlerReport = this.handlerReport.bind(this);
        this.renderDownloadButton = this.renderDownloadButton.bind(this);
        this.renderCopyButton = this.renderCopyButton.bind(this);
        this.renderReportButton = this.renderReportButton.bind(this);
    }

    render() {
        if (this.state.deleted) {
            return <span/>;
        }

        const header = this.renderHeader();
        const footer = this.renderFooter();
        const pano = this.renderPano();
        const comment = this.renderComment();
        const uploadZone = this.renderUploadZone();
        const downloadButton = this.renderDownloadButton();
        const restoreButton = this.renderRestoreButton();
        const remoteViewerButton = this.renderRemoteViewerButton();
        const reportButton = this.renderReportButton();
        const shareButton = this.renderShareButton();
        const copyButton = this.renderCopyButton();
        const editStudyButton = <EditStudyModal study={this.props.study} refreshStudy={this.refreshStudy}/>;
        const deleteStudyButton = <DeleteStudyModal study={this.props.study} deleteStudy={this.deleteStudy}/>;

        let uploadMaxAge = this.props.uploadMaxAge;
        if (uploadMaxAge === '8760h0m0s') {
            uploadMaxAge = '1 год';
        }

        return (<>
            <Card className="mb-2">
                <Card.Header>
                    {header}
                </Card.Header>
                <Card.Body>
                    {pano}
                    {comment}
                    {uploadZone}
                    <Row>
                        <Col md="6">
                            {downloadButton}
                            {remoteViewerButton}
                            {restoreButton}
                            {reportButton}
                        </Col>
                        <Col md="6" className="text-right">
                            {shareButton}
                            {copyButton}
                            {editStudyButton}
                            {deleteStudyButton}
                        </Col>
                        <Col md="6"><small class="text-muted">Срок хранения: {uploadMaxAge}</small></Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    {footer}
                </Card.Footer>
            </Card>
            <br/>
        </>)
    }

    renderHeader() {
        const study = this.state.study;
        const status = mapStatusToBadge[study.status];
        const date = moment(study.createdAt).format("DD.MM.YYYY");
        const type = mapTypeToString[study.type];
        const restored = study.restored ? '(восстановление)' : '';

        return (
            <>
                {status} {date} {type} {restored}
            </>
        );
    }

    renderPano() {
        const study = this.state.study;

        if (!study.panoURL || study.panoURL.length === 0) {
            return <span/>
        }

        return <Pano studyID={study.studyID} panoURL={study.panoURL}/>
    }

    renderComment() {
        const study = this.state.study;
        let comment = <i>Отсутствует</i>

        if (study.comment !== '') {
            comment = study.comment;
        }

        return (
            <>
                <p>Комментарий:</p>
                <p>{comment}</p>
            </>
        );
    }

    renderRemoteViewerButton() {
        const study = this.state.study;
        if (study.status !== STUDY_STATUS_DONE) {
            return <span/>
        }

        if (!this.props.remoteViewerEnabled) {
            return <span/>
        }

        if (study.type === STUDY_TYPE_RG) {
            return <span/>
        }

        return <>
            &nbsp;
            <RemoteViewerModal study={this.state.study}
                               isAssistant={true}/>
        </>
    }

    refreshStudy() {
        axios.get("/api/1/assistant/studies/" + this.state.study.studyID, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            this.setState({
                study: response.data.study,
            })
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    deleteStudy() {
        this.setState({deleted: true})
    }

    renderUploadZone() {
        const study = this.state.study;

        if (study.status !== STUDY_STATUS_IN_WORK) {
            return <span/>
        }

        return (<>
            <UploadZone studyID={study.studyID}
                        refreshStudy={this.refreshStudy}/>
            &nbsp;
        </>);
    }

    handleDownload() {
        const studyID = this.state.study.studyID;

        axios.get("/api/1/assistant/studies/" + studyID + "/download", {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            const url = new URL(response.data.presignedURL);
            const pathParts = url.pathname.split("/");
            const filename = pathParts[pathParts.length - 1];

            saveAs(response.data.presignedURL, filename)
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    renderShareButton() {
        const study = this.state.study;

        if (study.status !== STUDY_STATUS_DONE) {
            return <span/>
        }

        return <>
            <ShareStudyModal studyID={study.studyID} />
            &nbsp;
        </>
    }

    renderCopyButton() {
        const study = this.state.study;

        if (study.status !== STUDY_STATUS_DONE) {
            return <span/>
        }

        return <CopyStudyModal studyID={study.studyID} currentPatientID={study.patientID}/>
    }

    renderDownloadButton() {
        const study = this.state.study;
        if (study.status !== STUDY_STATUS_DONE) {
            return <span/>
        }

        return (
            <>
                <Button variant="primary" onClick={this.handleDownload}>Скачать</Button>
            </>
        );
    }

    handlerRestore() {
        const studyID = this.state.study.studyID;

        axios.put("/api/1/assistant/studies/" + studyID + "/restore", {}, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(this.refreshStudy)
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    renderRestoreButton() {
        const study = this.state.study;
        if (study.status !== STUDY_STATUS_ARCHIVED) {
            return <span/>
        }

        return (
            <Button variant="secondary" onClick={this.handlerRestore}>Восстановить данные</Button>
        );
    }

    handlerReport() {
        const studyID = this.state.study.studyID;

        this.setState({reportBtnDisabled: true});

        axios.post("/api/1/assistant/studies/" + studyID + "/report", {
            reportFormat: "pdf",
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            this.setState({reportBtnDisabled: false});
            fetch("data:application/pdf;base64,"+response.data.report)
                .then(res => res.blob().then(blob => window.open(URL.createObjectURL(blob), '_blank')))
        }).catch((error) => {
            this.setState({reportBtnDisabled: false});
            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    renderReportButton() {
        if (!this.props.studyReportEnabled) {
            return <span/>
        }

        const study = this.state.study;
        if (study.status !== STUDY_STATUS_DONE) {
            return <span/>
        }

        if (!study.panoURL || study.panoURL.length === 0) {
            return <span/>
        }

        return (
            <>
                &nbsp;
                <Button variant="secondary" onClick={this.handlerReport} disabled={this.state.reportBtnDisabled}>Отчет</Button>
            </>
        );
    }

    renderFooter() {
        if (!this.state.study.creatorName) {
            return <span />
        }

        return <>
            Создатель: {this.state.study.creatorName}
        </>
    }
}

export default Study;
