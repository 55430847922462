import React from "react";
import {Breadcrumb, Button, Jumbotron} from "react-bootstrap";
import StudiesList from "./StudiesList";
import {loadCurrentPatientData, loadDoctorData, loadPatientStudiesListData} from "../store/actions";
import {connect} from "react-redux";
import moment from "moment";
import {Link} from "react-router-dom";

class PatientStudiesList extends React.Component {
    componentDidMount() {
        this.props.loadPatientData(this.props.patientID);
        this.props.loadDoctorData(this.props.doctorID);
    }

    render() {
        if (!this.props.patient) {
            return <p>загрузка...</p>;
        }

        const sex = this.renderSex(this.props.patient.sex);
        const birthday = this.renderBirthday(this.props.patient.birthday);
        const phone = this.renderPhone(this.props.patient.phone);
        const doctorLinkPath = "/assistant-area/doctors/" + this.props.doctorID + "/patients"
        const doctorName = this.renderDoctorName();
        const createApplicationLink = "/assistant-area/applications/create/" + this.props.doctorID + "/" + this.props.patientID;

        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
                    <Breadcrumb.Item href={doctorLinkPath} to={doctorLinkPath}>{doctorName}</Breadcrumb.Item>
                    <Breadcrumb.Item active>Пациент {this.props.patient.name}</Breadcrumb.Item>
                </Breadcrumb>
                <Jumbotron>
                    <h1>Пациент {this.props.patient.name}</h1>
                    <p>Пол: {sex}</p>
                    <p>Дата рождения: {birthday}.</p>
                    {phone}
                </Jumbotron>
                <p className="text-right">
                    <Button as={Link} variant="success"
                            to={createApplicationLink}>Создать исследование</Button>
                </p>
                <StudiesList studiesList={this.props.studiesList}
                             loadStudiesListData={(offset) => this.props.loadStudiesListData(this.props.patientID, offset)}
                             remoteViewerEnabled={this.props.remoteViewerEnabled}
                             studyReportEnabled={this.props.studyReportEnabled}
                             uploadMaxAge={this.props.uploadMaxAge} />
            </>
        );
    }

    renderDoctorName() {
        let doctorName = 'Клиника или врач';

        if (this.props.doctor) {
            doctorName = this.props.doctor.name ?? doctorName;
        }

        return doctorName;
    }

    renderSex(sex) {
        if (sex === 'female') {
            return 'жен.';
        }

        return 'муж.';
    }

    renderBirthday(birthday) {
        birthday = moment(birthday);

        return birthday.format("DD.MM.YYYY")
    }

    renderPhone(phone) {
        if (!phone) {
            return <span/>;
        }

        const url = "tel:" + phone;

        return (
            <p>
                Телефон: <a href={url}>{phone}</a>.
            </p>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        patient: state.currentPatient,
        doctor: state.doctor,
        studiesList: state.patientStudiesList,
        remoteViewerEnabled: state.settings.remoteViewerEnabled,
        studyReportEnabled: state.settings.studyReportEnabled,
        uploadMaxAge: state.settings.uploadMaxAge,
    }
}

const mapDispatchToProps = {
    loadPatientData: loadCurrentPatientData,
    loadStudiesListData: loadPatientStudiesListData,
    loadDoctorData: loadDoctorData,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientStudiesList)
