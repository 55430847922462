import React from "react";
import PatientsList from "./PatientsList";
import {loadDoctorData, loadDoctorsPatientsList} from "../store/actions";
import {connect} from "react-redux";
import {Breadcrumb, Button, ButtonGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import EditDoctorModal from "./EditDoctorModal";
import PasswordResetModal from "./PasswordResetModal";

class DoctorPatientsList extends React.Component {
    constructor(props) {
        super(props);

        this.loadPatientsList = this.loadPatientsList.bind(this);
        this.loadDoctorData = this.loadDoctorData.bind(this);
    }

    componentDidMount() {
        this.loadDoctorData();
    }

    loadDoctorData() {
        this.props.loadDoctorData(this.props.doctorID);
    }

    loadPatientsList(offset) {
        this.props.loadPatientsList(this.props.doctorID, offset);
    }

    render() {
        const doctorName = this.renderDoctorName();
        const title = this.renderTitle()
        const createApplicationLink = "/assistant-area/applications/create/" + this.props.doctorID;

        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
                    <Breadcrumb.Item active>{doctorName}</Breadcrumb.Item>
                </Breadcrumb>
                {title}
                <div className="text-right">
                    <ButtonGroup>
                        <Button as={Link} variant="success"
                                to={createApplicationLink}>Создать карточку пациента</Button>
                        <EditDoctorModal doctorID={this.props.doctorID} reloadDoctorsList={this.loadDoctorData} />
                        <PasswordResetModal doctorID={this.props.doctorID} doctorName={doctorName} />
                    </ButtonGroup>
                    <br/>
                    <br/>
                </div>
                <PatientsList doctorID={this.props.doctorID}
                              patientsList={this.props.patientsList}
                              loadPatientsList={this.loadPatientsList}/>
            </>
        )
    }

    renderTitle() {
        let doctorName = <i>не указано</i>

        if (this.props.doctor) {
            doctorName = this.props.doctor.name;
        }

        return (
            <h2>Пациенты клиента {doctorName}</h2>
        )
    }

    renderDoctorName() {
        let doctorName = 'Клиника или врач';

        if (this.props.doctor) {
            doctorName = this.props.doctor.name;
        }

        return doctorName;
    }
}

const mapStateToProps = (state) => {
    return {
        patientsList: state.doctorPatientsList,
        doctor: state.doctor,
    }
}

const mapDispatchToProps = {
    loadPatientsList: loadDoctorsPatientsList,
    loadDoctorData: loadDoctorData,
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorPatientsList)
