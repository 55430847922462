import React from "react";
import Pagination from "../../../components/Pagination";
import Study from "./Study";

const STUDIES_LIST_LIMIT = 30;

class StudiesList extends React.Component {
    componentDidMount() {
        this.props.loadStudiesListData();
    }

    render() {
        const cards = this.renderCards();
        const pagination = this.renderPagination();

        return (
            <>
                {cards}
                {pagination}
            </>
        );
    }

    renderCards() {
        if (!this.props.studiesList) {
            return <span/>
        }

        return this.props.studiesList.studies.map((study) => {
            return (
                <div key={study.studyID}>
                    <Study study={study}
                           remoteViewerEnabled={this.props.remoteViewerEnabled}
                           shared={this.props.shared}
                           threeDSegEnabled={this.props.threeDSegEnabled}
                           uploadMaxAge={this.props.uploadMaxAge} />
                    <br/>
                </div>
            )
        });
    }

    renderPagination() {
        return (
            <Pagination totalCount={this.props.studiesList.totalCount}
                        currentOffset={this.props.studiesList.currentOffset}
                        limit={STUDIES_LIST_LIMIT}
                        switchPage={this.loadStudiesListData}/>
        )
    }
}

export default StudiesList;
