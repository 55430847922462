import {combineReducers,} from 'redux';
import {studySharesListData} from "./actions";

export const showAdvReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DISPLAY_ADV':
            return true;
        case 'HIDE_ADV':
            return false;
        default:
            return state;
    }
};

export const advDataReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_ADV_DATA':
            return action.data;
        default:
            return state;
    }
};

export const doctorsListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DOCTORS_LIST_DATA':
            return action.data;
        default:
            return state;
    }
};

export const patientsInWorkListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PATIENTS_IN_WORK_LIST_DATA':
            return action.data;
        default:
            return state;
    }
};

export const patientsForRestoreListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PATIENTS_FOR_RESTORE_LIST_DATA':
            return action.data;
        default:
            return state;
    }
};

export const doctorPatientsListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DOCTORS_PATIENTS_LIST_DATA':
            return action.data;
        default:
            return state;
    }
};

export const editPatientReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_EDIT_PATIENT_DATA':
            return action.data;
        default:
            return state;
    }
}

export const currentPatientReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_CURRENT_PATIENT_DATA':
            return action.data;
        default:
            return state;
    }
}

export const patientStudiesListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PATIENT_STUDIES_LIST_DATA':
            return action.data;
        default:
            return state;
    }
}

export const studySharesListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_STUDY_SHARES_LIST_DATA':
            return action.data;
        default:
            return state;
    }
}

export const dropdownDoctorsListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DROPDOWN_DOCTORS_LIST':
            return action.data;
        default:
            return state;
    }
}

export const dropdownDoctorPatientsListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DROPDOWN_DOCTOR_PATIENTS_LIST':
            return action.data;
        default:
            return state;
    }
}

export const doctorReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DOCTOR_DATA':
            return action.data.doctor;
        default:
            return state;
    }
}

export const commonStudiesListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_COMMON_STUDIES_LIST_DATA':
            return action.data;
        default:
            return state;
    }
}

export const settingsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_SETTINGS_DATA':
            return action.data;
        default:
            return state;
    }
}

export const countersReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_COUNTERS_DATA':
            return action.data;
        default:
            return state;
    }
}

export const reducers = combineReducers({
    showAdv: showAdvReducer,
    advData: advDataReducer,
    doctorsList: doctorsListReducer,
    patientsInWorkList: patientsInWorkListReducer,
    patientsForRestoreList: patientsForRestoreListReducer,
    doctorPatientsList: doctorPatientsListReducer,
    editPatient: editPatientReducer,
    currentPatient: currentPatientReducer,
    patientStudiesList: patientStudiesListReducer,
    studySharesList: studySharesListReducer,
    dropdownDoctorsList: dropdownDoctorsListReducer,
    dropdownDoctorPatientsList: dropdownDoctorPatientsListReducer,
    doctor: doctorReducer,
    commonStudiesList: commonStudiesListReducer,
    settings: settingsReducer,
    counters: countersReducer,
});
