import React from "react";
import PatientsList from "./PatientsList";
import {connect} from "react-redux";
import {loadSharedPatientsList} from "../store/actions";

class SharedPatientsList extends PatientsList {}

const mapStateToProps = (state) => {
    return {
        patientsList: state.sharedPatientsList,
    }
}

const mapDispatchToProps = {
    loadPatientsList: loadSharedPatientsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedPatientsList)
