import React from "react";
import {addResponseMessage, addUserMessage, dropMessages, setBadgeCount, Widget} from "react-chat-widget";
import './Chat.css'
import {getUserID, logout} from "../../../../components/Auth";
import axios from "axios";
import {getCentrifuge} from "../../../../components/Centrifuge";

class Chat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isWidgetOpened: false,
            lastMessageID: -1,
        };

        this.centrifuge = getCentrifuge();
        this.incomingMessageSound = new Audio('/sounds/notification.mp3');
        this.incomingMessageSound.autoplay = true;
        this.incomingMessageSound.muted = true;

        this.handleNewUserMessage = this.handleNewUserMessage.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadMessages = this.loadMessages.bind(this);
        this.addMessage = this.addMessage.bind(this);
        this.addCentrifugeMessage = this.addCentrifugeMessage.bind(this);
        this.handleCentrifugeEvent = this.handleCentrifugeEvent.bind(this);
        this.markMessagesRead = this.markMessagesRead.bind(this);
    }

    componentDidMount() {
        this.centrifugeSubscription = this.centrifuge.subscribe("#" + getUserID(), this.handleCentrifugeEvent);
        this.centrifuge.connect();

        this.loadMessages();
    }

    componentWillUnmount() {
        if (this.centrifugeSubscription) {
            this.centrifugeSubscription.unsubscribe();
        }

        this.centrifuge.disconnect();
    }

    loadMessages() {
        dropMessages();

        axios.get("/api/1/doctor/messenger/messages")
            .then((response) => {
                const messages = response.data.messages;

                for (let i = messages.length - 1; i >= 0; i--) {
                    const message = messages[i];

                    this.addMessage(message);

                    if (message.isAnswer) {
                        this.setState({
                            lastMessageID: message.messageID,
                        })
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }

    addMessage(message) {
        if (message.isAnswer) {
            addResponseMessage(message.text);

            setBadgeCount(message.isRead ? 0 : 1);
        } else {
            addUserMessage(message.text);
        }
    }

    addCentrifugeMessage(message) {
        // не добавляем сообщения врача, потому что виджет его сам добавил
        if (!message.isAnswer) {
            return;
        }

        this.setState({
            lastMessageID: message.messageID,
        })

        this.addMessage(message);

        if (this.state.isWidgetOpened) {
            this.markMessagesRead();
        }

        this.incomingMessageSound.muted = false;
        this.incomingMessageSound.play();
    }

    handleCentrifugeEvent(centrifugeEvent) {
        const event = centrifugeEvent.data;

        if (event.type === 'new_message') {
            this.addCentrifugeMessage(event.data);
        }
    }

    handleNewUserMessage(message) {
        axios.post("/api/1/doctor/messenger/messages/send", {
            text: message,
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        })
    }

    handleToggle(isWidgetOpened) {
        if (!isWidgetOpened) {
            return;
        }

        if (this.state.lastMessageID !== -1) {
            this.markMessagesRead();
        }
    }

    markMessagesRead() {
        axios.patch("/api/1/doctor/messenger/messages/mark-read", {
            messageIDs: [this.state.lastMessageID],
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    render() {
        return (
            <Widget
                handleNewUserMessage={this.handleNewUserMessage}
                handleToggle={this.handleToggle}
                title="Напишите ваше сообщение"
                subtitle="Операторы онлайн!"
                launcherOpenLabel="Открыть чат"
                launcherCloseLabel="Закрыть чат"
                sendButtonAlt="Отправить"
                emojis={true}
                showTimeStamp={false}
            />
        );
    }
}

export default Chat;
