import React from "react";
import {Alert, Button, Dropdown, Modal} from "react-bootstrap";
import axios from "axios";
import {logout} from "../../../components/Auth";

class PasswordResetModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            showInfo: false,
            showSuccess: false,
            showError: false,
            infoMsg: null,
            successMsg: null,
            errorMsg: null,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handlePasswordReset = this.handlePasswordReset.bind(this);
    }

    componentDidUpdate(prevProps, prevState, ss) {
        if (!prevState.show && this.props.doctorID) {

        }
    }

    handleOpen() {
        this.setState({
            show: true,
            showInfo: false,
            showSuccess: false,
            showError: false,
            infoMsg: null,
            successMsg: null,
            errorMsg: null,
        });
    }

    handleClose() {
        this.setState({
            show: false,
        });
    }

    handlePasswordReset() {
        this.setState({
            infoMsg: "Обработка запроса...",
            showInfo: true,
        });

        axios.patch(
            "/api/1/assistant/doctors/" + this.props.doctorID + "/reset-password",
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then(() => {
                this.setState({
                    showSuccess: true,
                    successMsg: "Пароль отправлен клиенту на почту.",

                    showInfo: false,
                }, () => setTimeout(this.handleClose, 3000))
            })
            .catch((error) => {
                let errorMsg = "Произошла непредвиденная ошибка. Обратитесь в поддержку.";

                if (error.response) {
                    errorMsg = error.response.data.error;
                }

                this.setState({
                    showError: true,
                    errorMsg: errorMsg,

                    showInfo: false,
                })

                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }

    render() {
        const button = this.renderButton();

        return (
            <>
                {button}

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Сброс пароля</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert show={this.state.showInfo}
                               key="pwd-reset-0"
                               variant="info">{this.state.infoMsg}</Alert>
                        <Alert show={this.state.showSuccess}
                               key="pwd-reset-1"
                               variant="success">{this.state.successMsg}</Alert>
                        <Alert show={this.state.showError}
                               key="pwd-reset-2"
                               variant="danger">{this.state.errorMsg}</Alert>
                        Вы уверены, что хотите сбросить пароль клиенту <strong>{this.props.doctorName}</strong>?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handlePasswordReset}>
                            Сбросить пароль
                        </Button>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Отмена
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    renderButton() {
        if (this.props.asItem) {
            return <Dropdown.Item onClick={this.handleOpen}>Сбросить пароль</Dropdown.Item>;
        }

        return <Button onClick={this.handleOpen} variant="secondary">Сбросить пароль</Button>
    }
}

export default PasswordResetModal;
