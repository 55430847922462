import React from "react";
import {Badge, Col, FormControl, ListGroupItem, Media} from "react-bootstrap";
import moment from "moment";

class DialogList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: "",
        };

        this.renderDialog = this.renderDialog.bind(this);
        this.renderSearch = this.renderSearch.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClickDialog = this.handleClickDialog.bind(this);
        this.renderReadFlag = this.renderReadFlag.bind(this);
        this.renderLastMessageTime = this.renderLastMessageTime.bind(this);
        this.renderLastMessageText = this.renderLastMessageText.bind(this);
    }

    render() {
        let dialogs = this.props.dialogs;

        if (this.state.searchQuery) {
            dialogs = dialogs.filter((dialog) => {
                return dialog.name.toLowerCase().includes(this.state.searchQuery.toLowerCase());
            });
        }

        dialogs = dialogs.map((dialog) => {
            return this.renderDialog(dialog);
        });

        const search = this.renderSearch();

        return (
            <Col md={5} className="px-0">
                <div className="bg-white">
                    <div className="messages-box">
                        <div className="list-group rounded-0">
                            {dialogs}
                        </div>
                    </div>
                </div>
                {search}
            </Col>
        );
    }

    handleSearch(e) {
        this.setState({searchQuery:e.target.value})
    }

    renderSearch() {
        return (
            <FormControl className="messenger-search"
                         placeholder="Поиск диалога..."
                         onChange={this.handleSearch}/>
        )
    }

    handleClickDialog(_, id) {
        this.props.chooseDialog(id);
    }

    renderDialog(dialog) {
        let classes = ["rounded-0", "messenger-dialog"];

        if (this.props.dialogID === dialog.dialogID) {
            classes.push("active");
            classes.push("text-white");
        } else {
            classes.push("list-group-item-light");
        }

        const readFlag = this.renderReadFlag(dialog.isAnswer, dialog.isRead);
        const lastMessageTime = this.renderLastMessageTime(dialog.lastMessageCreatedAt);
        const lastMessageText = this.renderLastMessageText(dialog.lastMessageText);

        return (
            <ListGroupItem key={dialog.dialogID}
                           action
                           className={classes.join(" ")}
                           onClick={(e) => this.handleClickDialog(e, dialog.dialogID)}>
                <Media>
                    <Media.Body>
                        <div className="d-flex align-items-center justify-content-between mb-1">
                            <h6 className="mb-0">{dialog.name}</h6>
                            <small className="small font-weight-bold">{lastMessageTime}</small>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-1">
                            {lastMessageText}
                            {readFlag}
                        </div>
                    </Media.Body>
                </Media>
            </ListGroupItem>
        );
    }

    renderReadFlag(isAnswer, isRead) {
        if (!isAnswer && isRead === false) {
            return (
                <Badge as="div" variant="primary" pill>&nbsp;</Badge>
            )
        }

        return (
            <span>&nbsp;</span>
        );
    }

    renderLastMessageTime(lastMessageCreatedAt) {
        if (!lastMessageCreatedAt) {
            return '';
        }

        return moment(lastMessageCreatedAt).fromNow();
    }

    renderLastMessageText(lastMessageText) {
        if (!lastMessageText) {
            return (
                <p className="font-italic mb-0 text-small">
                    нет сообщений
                </p>
            )
        }

        return (
            <p className="mb-0 text-small">
                {lastMessageText}
            </p>
        );
    }
}

export default DialogList;
