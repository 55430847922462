import React from "react";
import {Route, Switch} from "react-router-dom";
import {Container, Row, Col, Image} from "react-bootstrap";
import NotFound from "../../components/NotFound";
import SharedStudy from "./components/SharedStudy";
import BrowserCompatibilityAlert from "../../components/BrowserCompatibilityAlert";

class ExternalArea extends React.Component {
    render() {
        return <Container className="app">
            <BrowserCompatibilityAlert />
            <Row>
                <Col md="3">
                    <Image className="logo" src="/images/logo.png" alt={process.env.REACT_APP_WEBSITE_NAME} fluid/>
                </Col>
                <Col md="9">
                    <Switch>
                        <Route exact path="/external-area/">
                            <NotFound pathToHome="/"/>
                        </Route>
                        <Route exact
                               path="/external-area/studies/shared/:shareToken"
                               children={({match}) => (
                                   <SharedStudy shareToken={match.params.shareToken}/>
                               )}>
                        </Route>
                        <Route path="/external-area/*">
                            <NotFound pathToHome="/"/>
                        </Route>
                    </Switch>
                </Col>
            </Row>
        </Container>
    }
}

export default ExternalArea;
