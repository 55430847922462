import React from "react";
import BrowserCompatibilityAlert from "./BrowserCompatibilityAlert";
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import axios from "axios";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            success: '',
            error: '',
            email: '',
            disableSendBtn: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
    }

    handleChangeEmail(event) {
        this.setState({email: event.target.value})
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return
        }

        this.setState({disableSendBtn: true});

        axios.post('/api/1/auth/password/reset/request', {
            email: this.state.email,
        }).then((response) => {
            this.setState({
                disableSendBtn: false,
                success: 'На вашу почту отправлено письмо с ссылкой для восстановления',
            }, () => setTimeout(() => {
                window.location.href = '/';
            }, 3000));
        }).catch((error) => {
            let err_msg = 'Произошла неизвестная ошибка';

            console.log('auth/forgotPassword', error);

            if (error.hasOwnProperty('response') && error.response.status !== 200) {
                switch (error.response.status) {
                    case 500:
                        err_msg = 'Произошла ошибка сервера. Повторите запрос';
                        break;
                    case 400:
                        err_msg = 'Невалидный запрос';
                        if (error.response.data.error !== 'invalid_request') {
                            err_msg = error.response.data.error;
                        }
                        break;
                    default:
                        err_msg = 'Неизвестный код ошибки: ' + error.response.status;
                        break;
                }
            }

            this.setState({error: err_msg, disableSendBtn: false});
        });
    }

    render() {
        let success = '';
        if (this.state.success) {
            success = <p className="text-success">{this.state.success}</p>
        }

        let error = '';
        if (this.state.error) {
            error = <p className="text-danger">{this.state.error}</p>
        }

        return <Container>
            <BrowserCompatibilityAlert/>
            <Row>
                <Col md={{offset: 3, span: 6}}>
                    <h1>Восстановление пароля</h1>
                </Col>
            </Row>
            <Row>
                <Col md={{offset: 3, span: 6}}>
                    {success}
                    {error}
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="forgotPasswordFormEmail">
                            <Form.Label>E-mail:</Form.Label>
                            <Form.Control onChange={this.handleChangeEmail} required type="email"
                                          placeholder="username@example.com"/>
                            <Form.Text className="text-muted">
                                Введите адрес вашей почты. На него мы пришлем ссылку, где вы сможете изменить пароль.
                            </Form.Text>
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={this.state.disableSendBtn}>
                            Отправить на почту
                        </Button>
                        <Button variant="link" as={Link} to="/" className="ml-2">Войти снова</Button>
                    </Form>
                </Col>
            </Row>
        </Container>;
    }
}

export default ForgotPassword;
