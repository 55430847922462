import React from "react";
import {Jumbotron, Button} from "react-bootstrap";
import {loadCurrentPatientData, loadPatientStudiesListData} from "../store/actions";
import {connect} from "react-redux";
import moment from "moment";
import StudiesList from "./StudiesList";
import {Link} from "react-router-dom";
import SharePatientModal from "./SharePatientModal";
import RequestPatientSharingModal from "./RequestPatientSharingModal";

class PatientStudiesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accessDenied: false,
            notFound: false,
        };

        this.onLoadPatientDataError = this.onLoadPatientDataError.bind(this);
    }

    componentDidMount() {
        this.props.loadPatientData(this.props.patientID, this.onLoadPatientDataError);
    }

    onLoadPatientDataError(e) {
        if (!e.response) {
            console.error(e);

            return;
        }

        switch (e.response.status) {
            case 403:
                this.setState({accessDenied: true})
                break;
            case 404:
                this.setState({notFound: true})
                break;
        }
    }

    render() {
        if (this.state.accessDenied) {
            return this.renderAccessDenied();
        }

        if (this.state.notFound) {
            return this.renderNotFound();
        }

        if (!this.props.patient) {
            return <p>загрузка...</p>;
        }

        const sex = this.renderSex(this.props.patient.sex);
        const birthday = this.renderBirthday(this.props.patient.birthday);
        const phone = this.renderPhone(this.props.patient.phone);
        const sharePatientModal = this.renderSharePatientModal();

        return (
            <>
                <Jumbotron>
                    <h1>Пациент {this.props.patient.name}</h1>
                    <p>Пол: {sex}</p>
                    <p>Дата рождения: {birthday}.</p>
                    {phone}
                </Jumbotron>
                <p className="text-right">
                    {sharePatientModal}
                </p>
                <StudiesList studiesList={this.props.studiesList}
                             loadStudiesListData={(offset) => this.props.loadStudiesListData(this.props.patientID, offset)}
                             remoteViewerEnabled={this.props.remoteViewerEnabled}
                             shared={this.props.patient.shared}
                             threeDSegEnabled={this.props.threeDSegEnabled}
                             uploadMaxAge={this.props.uploadMaxAge} />
            </>
        );
    }

    renderSharePatientModal() {
        if (this.props.patient.shared) {
            return
        }

        return <SharePatientModal patientID={this.props.patientID}/>
    }

    renderSex(sex) {
        if (sex === 'female') {
            return 'жен.';
        }

        return 'муж.';
    }

    renderBirthday(birthday) {
        birthday = moment(birthday);

        return birthday.format("DD.MM.YYYY")
    }

    renderPhone(phone) {
        if (!phone) {
            return <span/>;
        }

        const url = "tel:" + phone;

        return (
            <p>
                Телефон: <a href={url}>{phone}</a>.
            </p>
        )
    }

    renderAccessDenied() {
        return (
            <>
                <Jumbotron>
                    <h1>Доступ запрещен.</h1>
                    <p>У вас недостаточно прав для просмотра данного пациента.</p>
                </Jumbotron>
                <p className="text-right">
                    <Button as={Link} to="/doctor-area/" variant="primary">Вернуться на главную</Button>
                    &nbsp;
                    <RequestPatientSharingModal patientID={this.props.patientID} />
                </p>
            </>
        )
    }

    renderNotFound() {
        return (
            <>
                <Jumbotron>
                    <h1>Не найдено.</h1>
                    <p>Карточки пациента не существует.</p>
                </Jumbotron>
                <p className="text-right">
                    <Button as={Link} to="/doctor-area/" variant="primary">Вернуться на главную</Button>
                </p>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        patient: state.currentPatient,
        studiesList: state.patientStudiesList,
        remoteViewerEnabled: state.settings.remoteViewerEnabled,
        threeDSegEnabled: state.settings.threeDSegEnabled,
        uploadMaxAge: state.settings.uploadMaxAge,
    }
}

const mapDispatchToProps = {
    loadPatientData: loadCurrentPatientData,
    loadStudiesListData: loadPatientStudiesListData,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientStudiesList)
