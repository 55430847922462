import React from "react";
import RFB from "@novnc/novnc/core/rfb";
import axios from "axios";
import {Button} from "react-bootstrap";
import {logout} from "./Auth";

const FULLSCREEN_DEFAULT = true;
const DISPLAY_DEFAULT_WIDTH = '1024px';
const DISPLAY_DEFAULT_HEIGHT = '768px';

class VNCViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: DISPLAY_DEFAULT_WIDTH,
            height: DISPLAY_DEFAULT_HEIGHT,
            loading: true,
            error: null,
            remoteSessionURL: null,
        };

        this.ref = React.createRef();
        this.handleDisconnect = this.handleDisconnect.bind(this);
        this.onFullScreenChange = this.onFullScreenChange.bind(this);
        this.requestFullscreen = this.requestFullscreen.bind(this);
        this.handleFullscreen = this.handleFullscreen.bind(this);
        this.sessionStart = this.sessionStart.bind(this);
    }

    handleDisconnect() {
        if (this.props.onDisconnect) {
            this.props.onDisconnect()
        }
    }

    componentDidMount() {
        this.setState({
            width: DISPLAY_DEFAULT_WIDTH,
            height: DISPLAY_DEFAULT_HEIGHT,
            loading: true,
            error: null,
        });

        let data = {};
        let config = {};
        let path = "";

        if (this.props.isAssistant) {
            path = "/api/1/assistant/studies/" + this.props.study.studyID + "/remote-session";
        }

        if (this.props.isExternal) {
            path = "/api/1/external/study/remote-session"
            config.headers = {
                "Authorization": "Bearer " + this.props.shareToken,
            };
        }

        if (!this.props.isAssistant && !this.props.isExternal) {
            path = "/api/1/doctor/patients/" + this.props.study.patientID + "/studies/" + this.props.study.studyID + "/remote-session";
        }

        axios.post(path, data, config)
            .then((response) => {
                this.setState({
                    loading: false,
                    remoteSessionURL: response.data.remoteSessionURL,
                })
            })
            .catch((error) => {
                console.log(error);

                let errorMsg = "Произошла непредвиденная ошибка. Обратитесь в поддержку.";

                if (error.response) {
                    errorMsg = error.response.data.error;
                }

                this.setState({
                    loading: false,
                    error: errorMsg,
                })

                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.opened !== prevProps.opened && !this.props.opened && this.rfb) {
            this.rfb.disconnect();
        }

        if (!prevState.remoteSessionURL && this.state.remoteSessionURL) {
            this.sessionStart();
        }
    }

    sessionStart() {
        if (this.rfb) {
            return;
        }

        document.addEventListener("fullscreenchange", this.onFullScreenChange)

        this.rfb = new RFB(this.ref.current, this.state.remoteSessionURL);
        this.rfb.resizeSession = true;
        this.rfb.compressionLevel = 2;
        this.rfb.qualityLevel = 6;

        this.rfb.addEventListener('disconnect', this.handleDisconnect)

        if (FULLSCREEN_DEFAULT) {
            this.requestFullscreen();
        }
    }

    requestFullscreen() {
        this.setState({
            width: window.screen.width + "px",
            height: window.screen.height + "px",
        }, () => this.ref.current.requestFullscreen());
    }

    componentWillUnmount() {
        if (this.rfb) {
            this.rfb.disconnect();
        }

        document.removeEventListener("fullscreenchange", this.onFullScreenChange);
    }

    onFullScreenChange() {
        if (!document.fullscreenElement) {
            this.setState({
                width: DISPLAY_DEFAULT_WIDTH,
                height: DISPLAY_DEFAULT_HEIGHT,
            });
        }
    }

    handleFullscreen() {
        this.requestFullscreen();
    }

    render() {
        if (this.state.loading) {
            return this.renderLoading();
        }

        if (this.state.error) {
            return this.renderError();
        }

        return <>
            <Button variant="info" onClick={this.handleFullscreen}>
                На весь экран
            </Button>
            <br/><br/>
            <div ref={this.ref} style={{width:this.state.width, height: this.state.height}} />
        </>;
    }

    renderLoading() {
        return <p>Загрузка...</p>
    }

    renderError() {
        return <p>{this.state.error}</p>
    }
}

export default VNCViewer;
