import React from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {loadEditPatientData} from "../store/actions";
import axios from "axios";
import {logout} from "../../../components/Auth";

class EditPatientModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: !!props.opened,
            name: "",
            sex: "",
            birthday: "",
            phone: "",
            error: "",
        };

        this.setShow = this.setShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeSex = this.onChangeSex.bind(this);
        this.onChangeBirthday = this.onChangeBirthday.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let newState = {};
        const prevState = this.state;

        if (nextProps.editPatient) {
            if (nextProps.editPatient.name && nextProps.editPatient.name !== prevState.name) {
                newState['name'] = nextProps.editPatient.name;
            }

            if (nextProps.editPatient.sex && nextProps.editPatient.sex !== prevState.sex) {
                newState['sex'] = nextProps.editPatient.sex;
            }

            if (nextProps.editPatient.birthday && nextProps.editPatient.birthday !== prevState.birthday) {
                newState['birthday'] = nextProps.editPatient.birthday;
            }

            if (nextProps.editPatient.phone && nextProps.editPatient.phone !== prevState.phone) {
                newState['phone'] = nextProps.editPatient.phone;
            }
        }

        this.setState(newState);
    }

    setShow(isOpened) {
        this.setState({opened: isOpened})
    }

    handleClose() {
        this.setShow(false)
    }

    handleShow() {
        this.props.loadEditPatientData(this.props.patientID)
        this.setShow(true)
    }

    handleSave(event) {
        axios.put("/api/1/assistant/patients/" + this.props.patientID, {
            "name": this.state.name,
            "sex": this.state.sex,
            "birthday": this.state.birthday,
            "phone": this.state.phone,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then(() => {
            this.props.loadPatientsList();
            this.handleClose();
        }).catch((error) => {
            if (!error.response) {
                console.log(error);
                return
            }

            this.setState({
                error: error.response.data.error,
            })

            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    onChangeName(e) {
        this.setState(
            {
                name: e.target.value,
            }
        )
    }

    onChangeSex(e) {
        this.setState(
            {
                sex: e.target.value,
            }
        )
    }

    onChangeBirthday(e) {
        this.setState(
            {
                birthday: e.target.value,
            }
        )
    }

    onChangePhone(e) {
        this.setState(
            {
                phone: e.target.value,
            }
        )
    }

    render() {
        if (!this.props.editPatient) {
            return this.renderEditButton()
        }

        return (
            <>
                {this.renderEditButton()}

                <Modal show={this.state.opened} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Редактирование пациента</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="editPatientName">
                                <Form.Label>ФИО</Form.Label>
                                <Form.Control value={this.state.name}
                                              placeholder="Введите ФИО пациента"
                                              onChange={this.onChangeName}/>
                            </Form.Group>

                            <Form.Group controlId="editPatientSex">
                                <Form.Label>Пол</Form.Label>
                                <Form.Check type="radio" label="муж." value="male" id="sexMale"
                                            checked={this.state.sex === "male"}
                                            onChange={this.onChangeSex}/>
                                <Form.Check type="radio" label="жен." value="female" id="sexFemale"
                                            checked={this.state.sex === "female"}
                                            onChange={this.onChangeSex}/>
                            </Form.Group>

                            <Form.Group controlId="editPatientBirthday">
                                <Form.Label>Дата рождения</Form.Label>
                                <Form.Control type="date" value={this.state.birthday}
                                              placeholder="Введите дату рождения пациента"
                                              onChange={this.onChangeBirthday}/>
                            </Form.Group>

                            <Form.Group controlId="editPatientPhone">
                                <Form.Label>Номер телефона</Form.Label>
                                <Form.Control type="phone" value={this.state.phone}
                                              placeholder="Введите номер телефона пациента"
                                              onChange={this.onChangePhone}/>
                            </Form.Group>
                        </Form>
                        <Alert variant="danger" show={!!this.state.error}>{this.state.error}</Alert>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleSave}>
                            Сохранить
                        </Button>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Отмена
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    renderEditButton() {
        return <Button variant="primary" onClick={this.handleShow}>Редактировать</Button>
    }
}

const mapStateToProps = (state) => {
    return {
        editPatient: state.editPatient,
    }
};

export default connect(mapStateToProps, {
    loadEditPatientData: loadEditPatientData,
})(EditPatientModal);
