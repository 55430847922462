import './App.css';
import React from "react";
import AuthArea from "./components/AuthArea";
import AssistantArea from "./sections/assistant/AssistantArea";
import DoctorArea from "./sections/doctor/DoctorArea";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"
import NotFound from "./components/NotFound";
import {
    getUserRole,
    logout,
    expireCallback,
    setExpires,
    setUserID,
    setUserRole,
    getUserID,
    setCentrifugoToken, checkAuth
} from "./components/Auth";
import moment from 'moment'
import 'moment/locale/ru'
import {Provider} from "react-redux";  // without this line it didn't work
import assistantStore from "./sections/assistant/store/store";
import doctorStore from "./sections/doctor/store/store";
import store from "./sections/doctor/store/store";
import BrowserCompatibilityAlert from "./components/BrowserCompatibilityAlert";
import axios from "axios";
import ExternalArea from "./sections/external/ExternalArea";
import ForgotPassword from "./components/ForgotPassword";
import SharedStudy from "./sections/external/components/SharedStudy";
import ResetPassword from "./components/ResetPassword";
moment.locale('ru')

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {is_auth: false};

        this.authExpired = this.authExpired.bind(this);
        this.setAuthData = this.setAuthData.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        checkAuth();

        if (!getUserID()) {
            return;
        }

        this.setState({
            is_auth: true,
            user_role: getUserRole(),
        });
        expireCallback(this.authExpired);
    }

    componentWillUnmount() {
        logout();
    }

    authExpired() {
        console.log("auth expired");
        this.setState({is_auth: false});
        logout();
    }

    setAuthData(data) {
        setExpires(data.expires_in);
        setUserID(data.user_id);
        setUserRole(data.user_role);
        setCentrifugoToken(data.centrifugo_token);
        this.setState({
            is_auth: true,
            user_role: data.user_role,
        });
        expireCallback(this.authExpired);
    }

    logout() {
        console.log("user click logout");
        axios.delete('/api/1/auth/token', {
            headers: {
                "Content-Type": "application/json",
            }
        }).finally(() => {
            this.setState({is_auth: false});
            logout();
            window.location.href = '/';
        });
    }

    render() {
        if (!this.state.is_auth) {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route path="/external-area/">
                            <ExternalArea />
                        </Route>
                        <Route exact path="/forgot-password" component={ForgotPassword} />
                        <Route exact
                               path="/forgot-password/:resetToken"
                               children={({match}) => (
                                   <ResetPassword resetToken={match.params.resetToken}/>
                               )}>
                        </Route>
                        <Route path="*">
                            <AuthArea setAuthData={this.setAuthData}/>
                        </Route>
                    </Switch>
                </BrowserRouter>
            )
        }

        if (this.state.user_role === 'assistant') {
            return (<BrowserRouter>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/assistant-area/"/>
                        </Route>
                        <Route path="/assistant-area/">
                            <Provider store={assistantStore}>
                                <AssistantArea logout={this.logout}/>
                            </Provider>
                        </Route>
                        <Route path="/external-area/">
                            <ExternalArea />
                        </Route>
                        <Route path="*">
                            <NotFound pathToHome="/"/>
                        </Route>
                    </Switch>
                </BrowserRouter>
            )
        }

        if (this.state.user_role === 'doctor') {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/doctor-area/"/>
                        </Route>
                        <Route path="/doctor-area/">
                            <Provider store={doctorStore}>
                                <DoctorArea logout={this.logout}/>
                            </Provider>
                        </Route>
                        <Route path="/external-area/">
                            <ExternalArea />
                        </Route>
                        <Route path="*">
                            <NotFound pathToHome="/"/>
                        </Route>
                    </Switch>
                </BrowserRouter>
            )
        }

        return (
            <p>Неизвестная роль. Пожалуйста, обратитесь в диагностический центр за дополнительной информацией.</p>
        );
    }
}

export default App;
