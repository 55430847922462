import React from "react";
import {Badge, Button, Card, Jumbotron, Row, Col} from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver"
import NotFound from "../../../components/NotFound";
import {mapTypeToString} from "../../../components/Study";
import RemoteViewerModal from "../../../components/RemoteViewerModal";
import {logout} from "../../../components/Auth";

class SharedStudy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isNotFound: false,
            patientName: "Загрузка...",
            studyType: "",
            studyComment: "",
            studyCreatedAt: "",
            shareFlags: [],
        };

        this.handleDownload = this.handleDownload.bind(this);
    }

    componentDidMount() {
        axios.get("/api/1/external/study", {
            headers: {
                "Authorization": "Bearer " + this.props.shareToken,
            },
        })
            .then((response) => {
                this.setState(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 403:
                        case 404:
                            this.setState({isNotFound: true})
                            break;
                    }
                }
            });
    }

    render() {
        if (this.state.isNotFound) {
            return <NotFound pathToHome="/"/>
        }

        const studyCreatedAt = moment(this.state.studyCreatedAt).format("DD.MM.YYYY");
        const shareExpiredAt = moment(this.state.shareExpiredAt).format("DD.MM.YYYY");
        const type = mapTypeToString[this.state.studyType];
        const comment = this.renderComment();
        const downloadBtn = this.renderDownloadButton();
        const remoteViewerBtn = this.renderRemoteViewerButton();

        return <>
            <Jumbotron>
                <h1>{this.state.patientName}</h1>
            </Jumbotron>
            <Card className="mb-2">
                <Card.Header>
                    <Badge variant="success">Исполнено</Badge>&nbsp;
                    {studyCreatedAt}&nbsp;
                    {type}
                </Card.Header>
                <Card.Body>
                    {comment}
                    <Row>
                        <Col md="6">
                            {downloadBtn}
                            {remoteViewerBtn}
                        </Col>
                        <Col md="6" className="text-right">
                            <p>Ссылка действительна до {shareExpiredAt}.</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>;
    }

    renderComment() {
        let comment = <i>Отсутствует</i>

        if (this.state.studyComment !== '') {
            comment = this.state.studyComment;
        }

        return (
            <>
                <p>Комментарий:</p>
                <p>{comment}</p>
            </>
        );
    }

    handleDownload() {
        axios.get("/api/1/external/study/download", {
            headers: {
                "Authorization": "Bearer " + this.props.shareToken,
            },
        }).then((response) => {
            const url = new URL(response.data.presignedURL);
            const pathParts = url.pathname.split("/");
            const filename = pathParts[pathParts.length - 1];

            saveAs(response.data.presignedURL, filename)
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    renderDownloadButton() {
        if (!this.state.shareFlags.includes("download")) {
            return <span/>
        }

        return (
            <>
                <Button variant="primary" onClick={this.handleDownload}>Скачать</Button>
                &nbsp;
            </>
        );
    }

    renderRemoteViewerButton() {
        if (!this.state.shareFlags.includes("remote-viewer")) {
            return <span/>
        }

        return <>
            <RemoteViewerModal shareToken={this.props.shareToken}
                               isExternal={true}/>
        </>
    }
}

export default SharedStudy;
