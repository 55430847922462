import React from "react";
import {Alert, Button, Dropdown, Form, Modal} from "react-bootstrap";
import {CLIENT_FEATURE_FLAGS, CLIENT_FEATURE_FLAGS_NAME} from "../../../components/FeatureFlags";
import axios from "axios";
import {logout} from "../../../components/Auth";

class EditDoctorModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            name: '',
            email: '',
            featureFlags: new Set(),

            showInfo: false,
            infoMsg: null,
            showSuccess: false,
            successMsg: null,
            showError: false,
            errorMsg: null,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeFeatureFlag = this.onChangeFeatureFlag.bind(this);
        this.handleUpdateAccount = this.handleUpdateAccount.bind(this);
        this.loadDoctor = this.loadDoctor.bind(this);
    }

    loadDoctor() {
        axios.get('/api/1/assistant/doctors/' + this.props.doctorID, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((resp) => {
                const featureFlags = new Set();
                const doctor = resp.data.doctor;

                if (doctor.featureFlags) {
                    for (let i = 0; i < doctor.featureFlags.length; i++) {
                        featureFlags.add(doctor.featureFlags[i]);
                    }
                }

                this.setState({
                    name: doctor.name,
                    email: doctor.email,
                    featureFlags: featureFlags,
                });
            })
            .catch((error) => {
                let errorMsg = "Произошла непредвиденная ошибка. Обратитесь в поддержку.";

                if (error.response) {
                    errorMsg = error.response.data.error;
                }

                this.setState({
                    showError: true,
                    errorMsg: errorMsg,

                    showInfo: false,
                })

                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    handleOpen() {
        this.loadDoctor();
        this.setState({
            show: true,
            name: '',
            email: '',
            featureFlags: new Set(),

            showInfo: false,
            infoMsg: null,
            showSuccess: false,
            successMsg: null,
            showError: false,
            errorMsg: null,
        })
    }

    handleClose() {
        this.setState({
            show: false,
        })
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value,
        })
    }

    onChangeFeatureFlag(e) {
        const checked = e.target.checked;
        const name = e.target.value;

        this.setState((state, _) => {
            const featureFlags = state.featureFlags;

            if (checked) {
                featureFlags.add(name);
            } else {
                featureFlags.delete(name);
            }

            return {
                featureFlags: featureFlags,
            }
        });
    }

    handleUpdateAccount() {
        const data = {
            name: this.state.name,
            featureFlags: Array.from(this.state.featureFlags),
        }

        this.setState({
            showInfo: true,
            infoMsg: "Обработка запроса...",
        });

        axios.put(
            "/api/1/assistant/doctors/" + this.props.doctorID,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                }
            }
        )
            .then(() => {
                this.props.reloadDoctorsList();
                this.handleClose();
            })
            .catch((error) => {
                let errorMsg = "Произошла непредвиденная ошибка. Обратитесь в поддержку.";

                if (error.response) {
                    errorMsg = error.response.data.error;
                }

                this.setState({
                    showError: true,
                    errorMsg: errorMsg,

                    showInfo: false,
                })

                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    render() {
        const featureFlags = this.renderFeatureFlags();
        const button = this.renderButton();

        return (
            <>
                {button}

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Редактирование аккаунта</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert show={this.state.showInfo}
                               key="pwd-reset-0"
                               variant="info">{this.state.infoMsg}</Alert>
                        <Alert show={this.state.showSuccess}
                               key="pwd-reset-1"
                               variant="success">{this.state.successMsg}</Alert>
                        <Alert show={this.state.showError}
                               key="pwd-reset-2"
                               variant="danger">{this.state.errorMsg}</Alert>
                        <Form>
                            <Form.Group controlId="editAccountName">
                                <Form.Label>ФИО врача или название клиники</Form.Label>
                                <Form.Control value={this.state.name}
                                              placeholder="Введите ФИО врача или название клиники"
                                              onChange={this.onChangeName}/>
                            </Form.Group>

                            <Form.Group controlId="editAccountEmail">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control type="email"
                                              value={this.state.email}
                                              disabled={true}/>
                            </Form.Group>

                            <div className="mb-3">
                                {featureFlags}
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.handleUpdateAccount}>Сохранить</Button>
                        <Button variant="secondary" onClick={this.handleClose}>Отмена</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    renderButton() {
        if (this.props.asItem) {
            return <Dropdown.Item onClick={this.handleOpen}>Редактировать аккаунт</Dropdown.Item>
        }

        return <Button onClick={this.handleOpen}>Редактировать аккаунт</Button>
    }

    renderFeatureFlags() {
        const featureFlags = Array.from(this.state.featureFlags);

        return CLIENT_FEATURE_FLAGS.map((name, i) => (
                <Form.Check key={`feature-flag-key-` + i} label={CLIENT_FEATURE_FLAGS_NAME[i]}
                            name="feature-flags[]"
                            type="checkbox"
                            value={name}
                            checked={featureFlags.includes(name)}
                            onChange={this.onChangeFeatureFlag}
                            id={`feature-flag-` + name}/>
            )
        );
    }
}

export default EditDoctorModal;
