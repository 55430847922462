import React from "react";
import {Pagination as ReactPagination} from "react-bootstrap";

class Pagination extends React.Component {
    render() {
        if (!this.props.totalCount) {
            return this.renderEmptyPagination();
        }

        const pagesCount = Math.ceil(this.props.totalCount / this.props.limit);
        const activePageIndex = Math.floor(this.props.currentOffset / this.props.limit);

        if (pagesCount <= 1) {
            return this.renderEmptyPagination();
        }

        let pages = [];
        for (let index = 0; index < pagesCount; index++) {
            const pageNumber = index + 1;
            const pageOffset = index * this.props.limit;
            const isActive = index === activePageIndex;

            pages.push(
                <ReactPagination.Item key={index}
                                 active={isActive}
                                 onClick={(_) => this.props.switchPage(pageOffset)}>
                    {pageNumber}
                </ReactPagination.Item>
            );
        }

        return (<ReactPagination>{pages}</ReactPagination>)
    }

    renderEmptyPagination() {
        return (<span />)
    }
}

export default Pagination;
