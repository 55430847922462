import React from "react";
import {Link} from "react-router-dom";
import {Button, ButtonGroup, Dropdown, Modal, Table} from "react-bootstrap";
import {connect} from "react-redux";
import {loadDoctorsList} from "../store/actions";
import Pagination from "../../../components/Pagination";
import PasswordResetModal from "./PasswordResetModal";
import CreateDoctorModal from "./CreateDoctorModal";
import EditDoctorModal from "./EditDoctorModal";

const DOCTORS_LIST_LIMIT = 30

class DoctorsList extends React.Component {
    constructor(props) {
        super(props);

        this.switchPage = this.switchPage.bind(this);
    }

    componentDidMount() {
        this.props.loadDoctorsList();
    }

    switchPage(pageOffset) {
        this.props.loadDoctorsList(pageOffset);
    }

    render() {
        let doctors = (
            <tr>
                <td colSpan="2">Нет доступных элементов</td>
            </tr>
        )

        if (this.props.doctorsList.doctors.length > 0) {
            doctors = this.props.doctorsList.doctors.map((doctor) => {
                const doctorPatientListPath = '/assistant-area/doctors/' + doctor.doctorID + '/patients'
                const name = doctor.name && doctor.name.length > 0 ? doctor.name : <i>не указано</i>;
                return (
                    <tr key={doctor.doctorID}>
                        <td>{name}</td>
                        <td>{doctor.email}</td>
                        <td>
                            <Dropdown as={ButtonGroup}>
                                <Button as={Link} to={doctorPatientListPath} variant="secondary">Список пациентов</Button>{' '}

                                <Dropdown.Toggle split variant="secondary" />

                                <Dropdown.Menu>
                                    <EditDoctorModal asItem doctorID={doctor.doctorID}
                                                     reloadDoctorsList={this.props.loadDoctorsList} />
                                    <PasswordResetModal asItem doctorID={doctor.doctorID}
                                                        doctorName={doctor.name} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                )
            })
        }

        return (
            <>
                <p className="text-right">
                    <CreateDoctorModal defaultFeatureFlags={process.env.REACT_APP_DEFAULT_FLAGS ?? ""}
                                       reloadDoctorsList={this.props.loadDoctorsList} />
                </p>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Клиент</th>
                        <th>Email</th>
                        <th width="230">Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    {doctors}
                    </tbody>
                </Table>
                <Pagination totalCount={this.props.doctorsList.totalCount}
                            currentOffset={this.props.doctorsList.currentOffset}
                            limit={DOCTORS_LIST_LIMIT}
                            switchPage={this.switchPage}/>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorsList: state.doctorsList,
    };
}

export default connect(mapStateToProps, {loadDoctorsList})(DoctorsList);
