import React from "react";
import {Alert, Button, Col, Form, Jumbotron, Row} from "react-bootstrap";

class Step3 extends React.Component {
    render() {
        return <>
            <h3>Шаг 3 - проверка данных</h3>
            <Alert show={this.props.error ?? false}
                   key="error"
                   variant="danger">{this.props.error}</Alert>
            <Form>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>ФИО пациента</Form.Label>
                            <Form.Control value={this.props.patientName} disabled />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Телефон пациента</Form.Label>
                            <Form.Control value={this.props.patientPhone} disabled />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Описание исследования</Form.Label>
                            <Form.Control as="textarea" rows="5" value={this.props.studyComment} disabled />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Выбранный центр:</Form.Label>
                            <Form.Control disabled value={this.props.locationName} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Дата и время:</Form.Label>
                            <Form.Control disabled value={this.props.scheduleDate + " " + this.props.scheduleSlotName} />
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="text-right">
                    <Button type="button" variant="secondary" onClick={this.props.prevStep}>Назад</Button>{' '}
                    <Button type="button" variant="primary" onClick={this.props.schedule}>Записать пациента на исследование</Button>
                </Form.Group>
            </Form>
        </>;
    }
}

export default Step3;
