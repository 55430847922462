import moment from "moment";

const EXPIRES_AT = 'expires_at';
const USER_ID = 'user_id';
const USER_ROLE = 'user_role';
const CENTRIFUGO_TOKEN = 'centrifugo_token';

export function setExpires(expiresIn) {
    let now = moment.now() / 1000; // в секундах
    let expires_at = now + expiresIn;

    console.log("auth created in " + moment().format('DD.MM.YYYY HH:mm:ss'));

    localStorage.setItem(EXPIRES_AT, expires_at);
}

export function setUserID(userID) {
    localStorage.setItem(USER_ID, userID);
}

export function setUserRole(userRole) {
    localStorage.setItem(USER_ROLE, userRole);
}

export function setCentrifugoToken(centrifugoToken) {
    localStorage.setItem(CENTRIFUGO_TOKEN, centrifugoToken);
}

let timer_id = null;

export function expireCallback(timeout_callback) {
    let expires_at = localStorage.getItem(EXPIRES_AT);

    let now = moment.now() / 1000; // в секундах
    const expires_in = expires_at - now;

    clearTimeout(timer_id);

    timer_id = setTimeout(timeout_callback, expires_in * 1000);
}

export function checkAuth() {
    let expires_at = localStorage.getItem(EXPIRES_AT);

    if (expires_at) {
        console.log("auth alive untill " + moment.unix(expires_at).format('DD.MM.YYYY HH:mm:ss'));
    }

    let now = moment.now() / 1000; // в секундах
    const expires_in = expires_at - now;

    if (expires_in <= 0) {
        logout();
    }
}

export function logout() {
    clearTimeout(timer_id);
    localStorage.removeItem(EXPIRES_AT);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_ROLE);
    localStorage.removeItem(CENTRIFUGO_TOKEN);
}

export function getUserRole() {
    return localStorage.getItem(USER_ROLE);
}

export function getUserID() {
    return localStorage.getItem(USER_ID)
}

export function getCentrifugoToken() {
    return localStorage.getItem(CENTRIFUGO_TOKEN)
}
