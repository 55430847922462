import React from "react";
import {Button, ButtonGroup, Col, Form, Jumbotron, Row} from "react-bootstrap";

class Step4 extends React.Component {
    render() {
        return <>
            <h3>Успех!</h3>
            <p>Вы записали пациента на исследование!</p>
            <Button variant="primary" onClick={this.props.nextStep}>Записать ещё</Button>
        </>;
    }
}

export default Step4;
