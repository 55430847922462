import React from "react";
import {Alert, Button, Modal, Table, Form} from "react-bootstrap";
import {connect} from "react-redux";
import axios from "axios";
import {withRouter} from "react-router-dom";
import {loadStudySharesListData} from "../store/actions";
import {logout} from "../../../components/Auth";

class ShareStudyModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,

            successMsg: null,
            errorMsg: null,

            email: "",
            downloadChecked: true,
            remoteViewerChecked: false,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleShareDelete = this.handleShareDelete.bind(this);
        this.handleShareMail = this.handleShareMail.bind(this);
        this.shareStudy = this.shareStudy.bind(this);
    }

    handleOpen() {
        this.setState({
            show: true,
        }, () => {
            this.props.loadStudyShares(this.props.studyID);
        });
    }

    handleClose() {
        this.setState({
            show: false,
            successMsg: null,
            errorMsg: null,
        });
    }

    handleSave() {
        this.handleClose();
    }

    shareStudy() {
        const flags = [];

        if (this.state.downloadChecked) {
            flags.push("download");
        }

        if (this.state.remoteViewerChecked) {
            flags.push("remote-viewer");
        }

        axios.post("/api/1/assistant/studies/" + this.props.studyID + "/external-shares", {
            email: this.state.email,
            flags: flags,
        })
            .then(() => {
                this.setState({
                    email: "",
                    downloadChecked: true,
                    remoteViewerChecked: false,
                    successMsg: "На введенную почту придет письмо с анонимной ссылкой с выбранными кнопками.",
                    errorMsg: null,
                }, () => this.props.loadStudyShares(this.props.studyID))

                setTimeout(() => {
                    this.setState({
                        successMsg: null,
                        errorMsg: null,
                    })
                }, 3000);
            }).catch((error) => {
            this.setState({
                successMsg: null,
                errorMsg: error.response.data.error ?? "Произошла ошибка. Попробуйте позже",
            });

            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    handleShareDelete(shareUUID) {
        axios.delete("/api/1/assistant/studies/" + this.props.studyID + "/external-shares/" + shareUUID)
            .then(() => this.setState({
                successMsg: null,
                errorMsg: null,
            }, () => this.props.loadStudyShares(this.props.studyID)))
            .catch((error) => {
                console.log("handleShareDelete", error.response.data);

                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    handleShareMail(shareUUID) {
        axios.post("/api/1/assistant/studies/" + this.props.studyID + "/external-shares/" + shareUUID + "/mail")
            .then(() => this.setState({
                successMsg: "На введенную почту придет письмо с анонимной ссылкой с выбранными кнопками.",
                errorMsg: null,
            }, () => setTimeout(() => {
                this.setState({
                    successMsg: null,
                    errorMsg: null,
                })
            }, 3000)))
            .catch((error) => {
                console.log("handleShareMail", error.response.data);

                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    render() {
        if (!this.props.studyExternalShareEnabled) {
            return <span />;
        }

        const sharedList = this.renderSharedList();
        const checkboxes = this.renderCheckboxes();

        return <>
            <Button onClick={this.handleOpen} variant="warning" title="Поделиться с внешним контактом">П</Button>

            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Поделиться с внешним контактом</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert show={!!this.state.successMsg}
                           key="share-1"
                           variant="success">{this.state.successMsg}</Alert>
                    <Alert show={!!this.state.errorMsg}
                           key="share-2"
                           variant="danger">{this.state.errorMsg}</Alert>
                    <p>Введите почту человека, с которым нужно поделиться исследованием:</p>
                    <Form.Control type="email"
                                  placeholder="Введите email"
                                  value={this.state.email}
                                  onChange={(e) => this.setState({email:e.target.value})}
                    />
                    {checkboxes}
                    <Button variant="success" onClick={this.shareStudy}>Добавить</Button>
                    {sharedList}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>
                        Готово
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }

    renderCheckboxes() {
        let checkboxes = [
            <Form.Check
                type="checkbox"
                id="download-btn"
                label="Разрешить скачивать"
                key="download"
                checked={this.state.downloadChecked}
                onChange={(e) => this.setState({downloadChecked: e.target.checked})}
                disabled={!this.props.studyExternalShareRemoteViewerEnabled}
            />
        ];

        if (this.props.studyExternalShareRemoteViewerEnabled) {
            checkboxes.push(
                <Form.Check
                    type="checkbox"
                    id="remote-viewer-btn"
                    label="Разрешить смотреть онлайн"
                    key="remote-viewer"
                    checked={this.state.remoteViewerChecked}
                    onChange={(e) => this.setState({remoteViewerChecked: e.target.checked})}
                />
            );
        }

        return <div className="mt-3 mb-3">
            {checkboxes}
        </div>;
    }

    renderSharedList() {
        if (this.props.studyShares.length === 0) {
            return <span/>
        }

        const shares = this.renderShares();

        return <>
            <hr />
            <br/>
            <p>Поделились с:</p>
            <Table size="sm" borderless>
                <tbody>
                {shares}
                </tbody>
            </Table>
        </>
    }

    renderShares() {
        return this.props.studyShares.map((share) => {
            return (
                <tr key={share.shareUUID}>
                    <td>{share.email}</td>
                    <td width="200">
                        <Button size="sm" variant="info"
                                title="Повторно отправить ссылку на email"
                                onClick={() => this.handleShareMail(share.shareUUID)}
                        >Отправить повторно</Button>
                    </td>
                    <td width="30">
                        <Button size="sm" variant="danger"
                                onClick={() => this.handleShareDelete(share.shareUUID)}>X</Button>
                    </td>
                </tr>
            )
        })
    }
}

const mapStateToProps = (state) => {
    return {
        studyShares: state.studySharesList.shares,
        studyExternalShareEnabled: state.settings.studyExternalShareEnabled,
        studyExternalShareRemoteViewerEnabled: state.settings.studyExternalShareRemoteViewerEnabled,
    }
}

const mapDispatchToProps = {
    loadStudyShares: loadStudySharesListData,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareStudyModal));
