import React from "react";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import {logout} from "../../../components/Auth";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
    {
        original: "https://storage.yandexcloud.net/dddseg-s3-public/24_1.webp",
    },
    {
        original: "https://storage.yandexcloud.net/dddseg-s3-public/AKK2.webp",
    },
    {
        original: "https://storage.yandexcloud.net/dddseg-s3-public/ALL.webp",
    },
    {
        original: "https://storage.yandexcloud.net/dddseg-s3-public/F4_N.webp",
    },
    {
        original: "https://storage.yandexcloud.net/dddseg-s3-public/LALL.webp",
    },
    {
        original: "https://storage.yandexcloud.net/dddseg-s3-public/MR_4N.webp",
    },
    {
        original: "https://storage.yandexcloud.net/dddseg-s3-public/screenshot1.webp",
    },
    {
        original: "https://storage.yandexcloud.net/dddseg-s3-public/screenshot2.webp",
    },
    {
        original: "https://storage.yandexcloud.net/dddseg-s3-public/screenshot3.webp",
    },
    {
        original: "https://storage.yandexcloud.net/dddseg-s3-public/screenshot4.webp",
    },
];

class ThreeDSegmentationModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleOpen() {
        this.setState({
            show: true,
        }, () => {
            axios.post("/api/1/doctor/patients/" + this.props.study.patientID + "/studies/" + this.props.study.studyID + "/3dseg-click").catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
        });
    }

    handleClose() {
        this.setState({
            show: false,
        });
    }

    render() {
        return <>
            <Button onClick={this.handleOpen} variant="success">3д сегментация</Button>

            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>3д сегментация</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ImageGallery items={images} />

                    <p>
                        Благодаря интеграции с партнерским сервисом, скоро станет доступна автоматическая 3D сегментация КЛКТ снимков с помощью нейросети.<br/>
                        Качественные 3д модели можно использовать для:
                    </p>

                    <ol>
                        <li>Изготовления элайнеров.</li>
                        <li>Планирования цифровой навигационной хирургии.</li>
                        <li>Улучшения коммуникации с пациентами.</li>
                    </ol>

                    <p>В интерфейсе будет добавлена специальная кнопка для удобства заказа данной услуги непосредственно из карточки исследования.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>
                        Готово
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }
}

export default ThreeDSegmentationModal;