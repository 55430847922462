import React from "react";
import {Alert, Button, Modal, Table} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {loadDoctorFriendsData, loadPatientSharesData} from "../store/actions";
import {connect} from "react-redux";
import axios from "axios";
import {withRouter} from "react-router-dom";
import {logout} from "../../../components/Auth";

class SharePatientModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            doctorFriends: [],
            selectedDoctor: [],

            successMsg: null,
            errorMsg: null,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDoctorChange = this.handleDoctorChange.bind(this);
        this.handleDoctorDelete = this.handleDoctorDelete.bind(this);
        this.sharePatient = this.sharePatient.bind(this);
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has("shareDoctorID")) {
            const shareDoctorID = parseInt(urlParams.get("shareDoctorID"))

            if (!isNaN(shareDoctorID)) {
                this.openShareDoctor(shareDoctorID)
            }
        }
    }

    openShareDoctor(shareDoctorID) {
        this.setState({
            show: true,
        }, () => {
            this.props.loadDoctorFriendsData();
            this.sharePatient({
                shareDoctorID: shareDoctorID,
            }, this.selectedDoctor);
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.doctorFriends.length !== this.props.doctorFriends.length ||
            prevProps.patientShares.length !== this.props.patientShares.length) {
            let doctorFriends = this.props.doctorFriends;

            if (this.props.patientShares.length > 0) {
                doctorFriends = doctorFriends.filter(friend => {
                    const shares = this.props.patientShares.filter(share => friend.doctorID === share.doctorID);

                    return shares.length === 0;
                })
            }

            this.setState({
                doctorFriends: doctorFriends,
            });
        }
    }

    handleOpen() {
        this.setState({
            show: true,
        }, () => {
            this.props.loadDoctorFriendsData();
            this.props.loadPatientSharesData(this.props.patientID);
        });
    }

    handleClose() {
        this.setState({
            show: false,
            successMsg: null,
            errorMsg: null,
        });
    }

    handleSave() {
        this.handleClose();
    }

    handleDoctorChange(options) {
        if (options.length !== 1) {
            this.setState({
                selectedDoctor: [],
            })

            return
        }

        let data = {}

        if (options[0].customOption) {
            data['shareDoctorEmail'] = options[0].name;
        } else {
            data['shareDoctorID'] = options[0].doctorID;
        }

        this.sharePatient(data, options);
    }

    sharePatient(data, selectedDoctor) {
        axios.post("/api/1/doctor/patients/" + this.props.patientID + "/shares", data)
            .then(() => {
                this.setState({
                    selectedDoctor: [],
                    successMsg: "Врачу на почту придет письмо с пациентом",
                    errorMsg: null,
                }, () => this.props.loadPatientSharesData(this.props.patientID))
            }).catch((error) => {
            this.setState({
                selectedDoctor: selectedDoctor,
                successMsg: null,
                errorMsg: error.response.data.error ?? "Произошла ошибка. Попробуйте позже",
            });

            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    handleDoctorDelete(doctorID) {
        axios.delete("/api/1/doctor/patients/" + this.props.patientID + "/shares/" + doctorID)
            .then(() => this.setState({
                successMsg: null,
                errorMsg: null,
            }, () => this.props.loadPatientSharesData(this.props.patientID)))
            .catch((error) => {
                console.log("handleDoctorDelete", error.response.data);

                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    render() {
        const sharedList = this.renderSharedList();

        return <>
            <Button onClick={this.handleOpen} variant="info">Поделиться</Button>

            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Поделиться пациентом</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert show={!!this.state.successMsg}
                           key="share-patient-1"
                           variant="success">{this.state.successMsg}</Alert>
                    <Alert show={!!this.state.errorMsg}
                           key="share-patient-2"
                           variant="danger">{this.state.errorMsg}</Alert>
                    <p>Выберите доктора из списка или введите E-mail:</p>
                    <Typeahead
                        id="choose-doctor"
                        labelKey="name"
                        allowNew
                        newSelectionPrefix="E-mail: "
                        onChange={this.handleDoctorChange}
                        options={this.state.doctorFriends}
                        placeholder="выберите с кем поделиться"
                        emptyLabel="введите e-mail врача"
                        selected={this.state.selectedDoctor}
                    />
                    {sharedList}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>
                        Готово
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }

    renderSharedList() {
        if (this.props.patientShares.length === 0) {
            return <span/>
        }

        const doctors = this.renderDoctors();

        return <>
            <br/>
            <p>Поделились с:</p>
            <Table size="sm" borderless>
                <tbody>
                {doctors}
                </tbody>
            </Table>
        </>
    }

    renderDoctors() {
        return this.props.patientShares.map((doctor) => {
            const name = doctor.name && doctor.name.length > 0 ? doctor.name : <i>не указано</i>;
            return (
                <tr key={doctor.doctorID}>
                    <td>{name}</td>
                    <td width="50"><Button size="sm" variant="danger"
                                           onClick={() => this.handleDoctorDelete(doctor.doctorID)}>X</Button></td>
                </tr>
            )
        })
    }
}

const mapStateToProps = (state) => {
    return {
        doctorFriends: state.doctorFriends,
        patientShares: state.patientShares,
    }
}

const mapDispatchToProps = {
    loadDoctorFriendsData: loadDoctorFriendsData,
    loadPatientSharesData: loadPatientSharesData,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SharePatientModal));
