import axios from "axios";
import {logout} from "../../../components/Auth";

export const setDoctor = (data) => ({
    type: 'SET_DOCTOR',
    data: data.doctor,
});

export const loadDoctorData = () => {
    return (dispatch) => {
        axios.get("/api/1/doctor/me")
            .then((response) => {
                dispatch(setDoctor(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout()
                    window.location.reload();
                }
            })
    };
};

export const displayAdv = () => ({
    type: 'DISPLAY_ADV',
});

export const hideAdv = () => ({
    type: 'HIDE_ADV',
});

export const setAdvData = (data) => ({
    type: 'SET_ADV_DATA',
    data: data,
});

export const loadAdvDataList = () => {
    return (dispatch) => {
        axios.get("/api/1/doctor/adv")
            .then((response) => {
                dispatch(setAdvData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const patientsListData = (data) => ({
    type: 'SET_PATIENTS_LIST_DATA',
    data: data,
});

export const loadPatientsList = (offset = 0) => {
    return (dispatch) => {
        let params = {};

        if (offset > 0) {
            params.offset = offset;
        }

        axios.get("/api/1/doctor/patients", {
            params: params,
        })
            .then((response) => {
                dispatch(patientsListData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const sharedPatientsListData = (data) => ({
    type: 'SET_SHARED_PATIENTS_LIST_DATA',
    data: data,
});

export const loadSharedPatientsList = (offset = 0) => {
    return (dispatch) => {
        let params = {};

        if (offset > 0) {
            params.offset = offset;
        }

        axios.get("/api/1/doctor/patients/shared", {
            params: params,
        })
            .then((response) => {
                dispatch(sharedPatientsListData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const currentPatientData = (data) => ({
    type: 'SET_CURRENT_PATIENT_DATA',
    data: data.patient,
});

export const loadCurrentPatientData = (patientID = 0, errorCallback = null) => {
    return (dispatch) => {
        axios.get("/api/1/doctor/patients/" + patientID)
            .then((response) => {
                dispatch(currentPatientData(response.data))
            }).catch(errorCallback);
    }
};

export const patientStudiesListData = (data) => ({
    type: 'SET_PATIENT_STUDIES_LIST_DATA',
    data: data,
});

export const loadPatientStudiesListData = (patientID = 0, offset = 0) => {
    return (dispatch) => {
        let params = {};

        if (offset > 0) {
            params.offset = offset;
        }

        axios.get("/api/1/doctor/patients/" + patientID + "/studies", {
            params: params,
        })
            .then((response) => {
                dispatch(patientStudiesListData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const settingsData = (data) => ({
    type: 'SET_SETTINGS_DATA',
    data: data,
});

export const loadSettingsData = () => {
    return (dispatch) => {
        axios.get("/api/1/doctor/settings")
            .then((response) => {
                dispatch(settingsData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const doctorFriendsData = (data) => ({
    type: 'SET_DOCTOR_FRIENDS_DATA',
    data: data.friends,
});

export const loadDoctorFriendsData = () => {
    return (dispatch) => {
        axios.get("/api/1/doctor/friends")
            .then((response) => {
                dispatch(doctorFriendsData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};

export const patientSharesData = (data) => ({
    type: 'SET_PATIENT_SHARES_DATA',
    data: data.doctors,
});

export const loadPatientSharesData = (patientID) => {
    return (dispatch) => {
        axios.get("/api/1/doctor/patients/" + patientID + "/shares")
            .then((response) => {
                dispatch(patientSharesData(response.data))
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }
};
