import Centrifuge from "centrifuge";
import {getCentrifugoToken} from "./Auth";

let centrifuge = null;

export function getCentrifuge() {
    if (!centrifuge) {
        let protocol = 'ws';

        if (window.location.protocol === 'https:') {
            protocol = 'wss';
        }

        centrifuge = new Centrifuge(protocol + "://" + window.location.host + "/connection/websocket", {
            subscribeEndpoint: '/api/1/auth/centrifugo/subscribe'
        });
        centrifuge.setToken(getCentrifugoToken());
    }

    return centrifuge;
}
