import React from "react";
import {Alert, Button, Modal} from "react-bootstrap";
import axios from "axios";
import {logout} from "../../../components/Auth";

class RequestPatientSharingModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,

            successMsg: null,
            errorMsg: null,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleRequestPatientShare = this.handleRequestPatientShare.bind(this);
    }

    handleOpen() {
        if (this.closeTimerID > 0) {
            clearTimeout(this.closeTimerID);
            this.closeTimerID = -1;
        }

        this.setState({
            show: true,
            successMsg: null,
            errorMsg: null,
        });
    }

    handleClose() {
        this.setState({
            show: false,
            successMsg: null,
            errorMsg: null,
        });
    }

    handleRequestPatientShare() {
        axios.post("/api/1/doctor/patients/" + this.props.patientID + "/shares/request")
            .then(() => {
            this.setState({
                selectedDoctor: [],
                successMsg: "Запрос отправлен успешно",
                errorMsg: null,
            }, () => {
                this.closeTimerID = setTimeout(this.handleClose, 3000);
            })
        }).catch((error) => {
            this.setState({
                successMsg: null,
                errorMsg: error.response.data.error ?? "Произошла ошибка. Попробуйте позже",
            });

            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    render() {
        return <>
            <Button onClick={this.handleOpen} variant="info">Запросить доступ у владельца</Button>

            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Запрос доступа</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert show={!!this.state.successMsg}
                           key="share-patient-request-1"
                           variant="success">{this.state.successMsg}</Alert>
                    <Alert show={!!this.state.errorMsg}
                           key="share-patient-request-2"
                           variant="danger">{this.state.errorMsg}</Alert>
                    <p>Запросить доступ у владельца пациента?</p>
                    <p>Запрос придет владельцу пациента на почту.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleRequestPatientShare}>Запросить доступ</Button>
                    <Button variant="secondary" onClick={this.handleClose}>Отмена</Button>
                </Modal.Footer>
            </Modal>
        </>;
    }
}

export default RequestPatientSharingModal;
