import React from "react";
import {Alert, Col, Container, Row} from "react-bootstrap";

const isChrome = () => !!window.chrome;

class BrowserCompatibilityAlert extends React.Component {
    constructor() {
        super();

        this.state = {
            show: !isChrome(),
        };

        this.close = this.close.bind(this);
    }

    close() {
        this.setState({
            show: false,
        })
    }

    render() {
        if (!this.state.show) {
            return <span />;
        }

        return (
            <Row>
                <Col md={12}>
                    <Alert variant="warning" dismissible onClose={this.close}>
                        Для корректной работы сайта рекомендуем использовать браузер <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> или <a
                        href="https://browser.yandex.ru/" target="_blank">Yandex</a> или <a href="https://www.microsoft.com/ru-ru/edge/home" target="_blank">MS Edge</a>.
                    </Alert>
                </Col>
            </Row>
        );
    }
}

export default BrowserCompatibilityAlert;
