import React from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import axios from "axios";
import {CLIENT_FEATURE_FLAGS, CLIENT_FEATURE_FLAGS_NAME} from "../../../components/FeatureFlags";
import {logout} from "../../../components/Auth";

class CreateDoctorModal extends React.Component {
    constructor(props) {
        super(props);

        let featureFlags = new Set();

        if (this.props.defaultFeatureFlags.length > 0) {
            featureFlags = new Set(this.props.defaultFeatureFlags.split(","))
        }

        this.state = {
            show: false,
            name: '',
            email: '',
            featureFlags: featureFlags,

            showInfo: false,
            infoMsg: null,
            showSuccess: false,
            successMsg: null,
            showError: false,
            errorMsg: null,

            disableCreateBtn: false,
        };

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFeatureFlag = this.onChangeFeatureFlag.bind(this);
        this.handleCreateAccount = this.handleCreateAccount.bind(this);
    }

    handleShow() {
        this.setState({
            show: true,
            showInfo: false,
            infoMsg: null,
            showSuccess: false,
            successMsg: null,
            showError: false,
            errorMsg: null,
        })
    }

    handleClose() {
        this.setState({
            show: false,
        })
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value,
        })
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value,
        })
    }

    onChangeFeatureFlag(e) {
        const checked = e.target.checked;
        const name = e.target.value;

        this.setState((state, _) => {
            const featureFlags = state.featureFlags;

            if (checked) {
                featureFlags.add(name);
            } else {
                featureFlags.delete(name);
            }

            return {
                featureFlags: featureFlags,
            }
        });
    }

    handleCreateAccount() {
        const data = {
            name: this.state.name,
            email: this.state.email,
            featureFlags: Array.from(this.state.featureFlags),
        }

        this.setState({
            showInfo: true,
            infoMsg: "Обработка запроса...",
            disableCreateBtn: true,
        });

        axios.post(
                "/api/1/assistant/doctors",
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            )
            .then(() => {
                this.setState({
                    showSuccess: true,
                    successMsg: "Приветственное сообщение с паролем отправлено клиенту на почту.",

                    showInfo: false,
                    disableCreateBtn: false,
                }, () => {
                    setTimeout(() => {
                        this.props.reloadDoctorsList();
                        this.setState({
                            show: false,

                            name: '',
                            email: '',
                            featureFlags: new Set(),
                        })
                    }, 3000);
                })
            })
            .catch((error) => {
                let errorMsg = "Произошла непредвиденная ошибка. Обратитесь в поддержку.";

                if (error.response) {
                    errorMsg = error.response.data.error;
                }

                this.setState({
                    showError: true,
                    errorMsg: errorMsg,

                    showInfo: false,
                    disableCreateBtn: false,
                })

                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    render() {
        const featureFlags = this.renderFeatureFlags();

        return (
            <>
                <Button variant="success" onClick={this.handleShow}>Создать аккаунт</Button>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Создание аккаунта</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert show={this.state.showInfo}
                               key="pwd-reset-0"
                               variant="info">{this.state.infoMsg}</Alert>
                        <Alert show={this.state.showSuccess}
                               key="pwd-reset-1"
                               variant="success">{this.state.successMsg}</Alert>
                        <Alert show={this.state.showError}
                               key="pwd-reset-2"
                               variant="danger">{this.state.errorMsg}</Alert>
                        <Form>
                            <Form.Group controlId="createAccountName">
                                <Form.Label>ФИО врача или название клиники</Form.Label>
                                <Form.Control value={this.state.name}
                                              placeholder="Введите ФИО врача или название клиники"
                                              onChange={this.onChangeName}/>
                            </Form.Group>

                            <Form.Group controlId="createAccountEmail">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control type="email"
                                              value={this.state.email}
                                              placeholder="Введите e-mail"
                                              onChange={this.onChangeEmail}/>
                            </Form.Group>

                            <div className="mb-3">
                                {featureFlags}
                            </div>

                            <p>Пароль будет сгенерирован автоматически.</p>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleCreateAccount} disabled={this.state.disableCreateBtn}>Создать</Button>
                        <Button variant="secondary" onClick={this.handleClose}>Отмена</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    renderFeatureFlags() {
        const defaultFeatureFlags = Array.from(this.state.featureFlags);

        return CLIENT_FEATURE_FLAGS.map((name, i) => (
                <Form.Check key={`feature-flag-key-` + i} label={CLIENT_FEATURE_FLAGS_NAME[i]}
                            name="feature-flags[]"
                            type="checkbox"
                            value={name}
                            checked={defaultFeatureFlags.includes(name)}
                            onChange={this.onChangeFeatureFlag}
                            id={`feature-flag-` + name} />
            )
        );
    }
}

export default CreateDoctorModal;
