import React from "react";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import axios from "axios";
import {logout} from "../../../components/Auth";

const UPLOAD_STATUS_UPLOADING = "uploading";
const UPLOAD_STATUS_COMPLETED = "completed";
const UPLOAD_STATUS_PROCESSING = "processing";
const UPLOAD_STATUS_PROCESSED = "processed";

const UPLOAD_STATUSES_FOR_NOTICE = [UPLOAD_STATUS_COMPLETED, UPLOAD_STATUS_PROCESSING];

class UploadZone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            uploadUUID: "",
            uploadStatus: UPLOAD_STATUS_UPLOADING,
            timerID: -1,
        };

        this.getUploadParams = this.getUploadParams.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refreshUpload = this.refreshUpload.bind(this);
    }

    componentDidMount() {
        axios.post("/api/1/assistant/studies/" + this.props.studyID + "/upload", {}, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                this.setState({
                    uploadUUID: response.data.uploadUUID,
                    uploadStatus: response.data.status,
                })
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    componentDidUpdate(prevProps, prevState, _) {
        if (prevState.uploadStatus !== this.state.uploadStatus) {
            if (UPLOAD_STATUSES_FOR_NOTICE.includes(this.state.uploadStatus) && this.state.timerID === -1) {
                this.setState({
                    timerID: setInterval(this.refreshUpload, 1000)
                })
            }
        }

        if (UPLOAD_STATUS_PROCESSED === this.state.uploadStatus) {
            this.props.refreshStudy();
        }
    }

    refreshUpload() {
        axios.get("/api/1/assistant/uploads/" + this.state.uploadUUID, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                let newState = {
                    uploadStatus: response.data.status,
                };

                if (UPLOAD_STATUS_PROCESSED === response.data.status) {
                    clearInterval(this.state.timerID);
                    newState['timerID'] = -1;
                }

                this.setState(newState)
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    // specify upload params and url for your files
    async getUploadParams({file}) {
        if (this.state.uploadUUID === "") {
            throw new Error("upload don't created before uploading files")
        }

        let response = await axios.post("/api/1/assistant/uploads/" + this.state.uploadUUID + "/presigned-url", {
            filename: file.name,
            filesize: file.size,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });

        return {body: file, method: 'PUT', meta: {fileUrl: response.data.presignedURL}, url: response.data.presignedURL}
    }

    // called every time a file's `status` changes
    async handleChangeStatus({meta, file}, status) {
        console.log(status, meta, file)
    }

    // receives array of files that are done uploading when submit button is clicked
    handleSubmit(_, allFiles) {
        // validation: all files should be uploaded
        for (let i = 0; i < allFiles.length; i++) {
            if (allFiles[i].meta.status !== 'done') {
                return;
            }
        }

        const finalFiles = allFiles.map(f => f.meta.name);

        axios.post("/api/1/assistant/uploads/" + this.state.uploadUUID + "/complete", {
            finalFiles: finalFiles,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            this.setState({
                uploadStatus: response.data.status,
            })
        }).catch((err) => {
            if (!err.response) {
                console.error("completeUpload", this.state.uploadUUID, err)
                return;
            }

            if (err.response.status === 409) {
                this.setState({
                    uploadStatus: UPLOAD_STATUS_PROCESSING,
                });
            }

            if (err.response && err.response.status === 401) {
                window.location.reload();
            }
        });
    }

    render() {
        if (this.state.uploadStatus !== 'uploading') {
            return this.renderStatus()
        }

        return (
            <Dropzone
                getUploadParams={this.getUploadParams}
                onChangeStatus={this.handleChangeStatus}
                onSubmit={this.handleSubmit}
                inputContent="Перенесите сюда файлы или нажмите для выбора"
                submitButtonContent="Завершить загрузку"
                inputWithFilesContent="Добавить файлы"
            />
        )
    }

    renderStatus() {
        const mapStatusToState = {
            'completed': 'Загрузка завершена. Происходит обработка на сервере.',
            'processing': 'Загрузка завершена. Происходит обработка на сервере.',
            'processed': 'Обработка завершена. Если вы видите данное сообщение, значит произошла ошибка. Сообщите администратору.',
        };

        return <p>{mapStatusToState[this.state.uploadStatus]}</p>
    }
}

export default UploadZone;
