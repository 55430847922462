import React from "react";
import Pagination from "../../../components/Pagination";
import Study from "./Study";
import {STUDIES_LIST_LIMIT} from "../../../components/Study";

class StudiesList extends React.Component {
    componentDidMount() {
        this.props.loadStudiesListData();
    }

    render() {
        const cards = this.renderCards();
        const pagination = this.renderPagination();

        return (
            <>
                {cards}
                {pagination}
            </>
        );
    }

    renderCards() {
        if (!this.props.studiesList) {
            return <span/>
        }

        return this.props.studiesList.studies.map((study) => {
            return (
                <div key={study.studyID}>
                    <Study study={study}
                           remoteViewerEnabled={this.props.remoteViewerEnabled}
                           studyReportEnabled={this.props.studyReportEnabled}
                           uploadMaxAge={this.props.uploadMaxAge} />
                </div>
            )
        });
    }

    renderPagination() {
        return (
            <Pagination totalCount={this.props.studiesList.totalCount}
                        currentOffset={this.props.studiesList.currentOffset}
                        limit={STUDIES_LIST_LIMIT}
                        switchPage={this.props.loadStudiesListData}/>
        )
    }
}

export default StudiesList;
