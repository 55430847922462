import React from "react";
import {Button, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import Pagination from "../../../components/Pagination";
import moment from "moment";

const PATIENTS_LIST_LIMIT = 30;

class PatientsList extends React.Component {
    constructor(props) {
        super(props);

        this.switchPage = this.switchPage.bind(this);
    }

    componentDidMount() {
        this.props.loadPatientsList();
    }

    switchPage(pageOffset) {
        this.props.loadPatientsList(pageOffset);
    }

    render() {
        let patients = (
            <tr>
                <td colSpan="3">Нет доступных элементов</td>
            </tr>
        )

        if (this.props.patientsList.patients.length > 0) {
            patients = this.props.patientsList.patients.map((patient) => {
                const patientStudiesPath = '/doctor-area/patients/' + patient.patientID + '/studies'
                const name = patient.name && patient.name.length > 0 ? patient.name : <i>не указано</i>;
                let lastStudyCreatedAt = moment(patient.lastStudyCreatedAt);
                lastStudyCreatedAt = lastStudyCreatedAt.year() > 1 ? lastStudyCreatedAt.format("DD.MM.YYYY") : '-';

                return (
                    <tr key={patient.patientID}>
                        <td>{lastStudyCreatedAt}</td>
                        <td>{name}</td>
                        <td>
                            <Link to={patientStudiesPath}>
                                <Button variant="secondary">Список исследований</Button>
                            </Link>{' '}
                        </td>
                    </tr>
                )
            })
        }

        return (
            <>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th width="50">Дата последнего исследования</th>
                        <th>ФИО пациента</th>
                        <th width="370">Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    {patients}
                    </tbody>
                </Table>
                <Pagination totalCount={this.props.patientsList.totalCount}
                            currentOffset={this.props.patientsList.currentOffset}
                            limit={PATIENTS_LIST_LIMIT}
                            switchPage={this.switchPage}/>
            </>
        );
    }
}

export default PatientsList