import React from "react";
import {Button, ButtonGroup, ButtonToolbar, Table} from "react-bootstrap";
import Pagination from "../../../components/Pagination";
import TableSortedTh from "./TableSortedTh";
import {connect} from "react-redux";
import {loadCommonStudiesListData} from "../store/actions";
import moment from "moment";
import {mapTypeToString, STUDIES_LIST_LIMIT} from "../../../components/Study";
import {Link} from "react-router-dom";
import PeriodFilter from "./PeriodFilter";

class CommonStudiesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sortColName: "createdAt",
            sortDirection: "desc",
            filterPeriod: moment().startOf("month").format("YYYY-MM-DD"),
        };

        this.onColumnChange = this.onColumnChange.bind(this);
        this.onPeriodChange = this.onPeriodChange.bind(this);
    }

    componentDidMount() {
        this.props.loadStudiesListData();
    }

    onColumnChange(colName, direction) {
        if (!direction) {
            colName = "createdAt";
            direction = "desc";
        }

        this.setState({
            sortColName: colName,
            sortDirection: direction,
        }, () => {
            this.props.loadStudiesListData(this.state.filterPeriod, colName + '.' + direction, this.props.studiesList.currentOffset)
        })
    }

    render() {
        const periodFilter = this.renderPeriodFilter();
        const tableHeader = this.renderTableHeader();
        const studies = this.renderStudies();
        const pagination = this.renderPagination();

        return <>
            {periodFilter}
            <br /><br />
            <Table striped bordered hover>
                <thead>
                <tr>
                    {tableHeader}
                </tr>
                </thead>
                <tbody>
                {studies}
                </tbody>
            </Table>
            {pagination}
        </>;
    }

    onPeriodChange(filterPeriod) {
        this.setState((state) => {
            if (state.filterPeriod === filterPeriod) {
                return null;
            }

            return {
                filterPeriod: filterPeriod,
            }
        }, () => {
            this.props.loadStudiesListData(this.state.filterPeriod, this.state.sortColName + '.' + this.state.sortDirection, 0)
        })
    }

    renderPeriodFilter() {
        return (
            <PeriodFilter currentPeriod={this.state.filterPeriod} onCurrentPeriodChanged={this.onPeriodChange} />
        )
    }

    renderTableHeader() {
        const createdAtColDirection = this.state.sortColName === "createdAt" ? this.state.sortDirection : null;
        const patientNameColDirection = this.state.sortColName === "patientName" ? this.state.sortDirection : null;
        const doctorNameColDirection = this.state.sortColName === "doctorName" ? this.state.sortDirection : null;
        const typeColDirection = this.state.sortColName === "type" ? this.state.sortDirection : null;

        return <>
            <TableSortedTh width="80"
                           name="createdAt"
                           direction={createdAtColDirection}
                           onColumnChange={this.onColumnChange}>Дата</TableSortedTh>
            <TableSortedTh name="patientName"
                           direction={patientNameColDirection}
                           onColumnChange={this.onColumnChange}>Пациент</TableSortedTh>
            <TableSortedTh name="doctorName"
                           direction={doctorNameColDirection}
                           onColumnChange={this.onColumnChange}>Клиент</TableSortedTh>
            <TableSortedTh width="80"
                           name="type"
                           direction={typeColDirection}
                           onColumnChange={this.onColumnChange}>Тип</TableSortedTh>
        </>;
    }

    renderStudies() {
        return this.props.studiesList.studies.map((study) => {
            const date = moment(study.createdAt).format("DD.MM.YYYY")
            const studyType = mapTypeToString[study.type];
            const patientPath = "/assistant-area/doctors/" + study.doctorID + "/patients/" + study.patientID + "/studies";
            const doctorPath = "/assistant-area/doctors/" + study.doctorID + "/patients";

            return (
                <tr key={study.studyID}>
                    <td>{date}</td>
                    <td>
                        <Link to={patientPath}>{study.patientName ?? '-'}</Link>
                    </td>
                    <td>
                        <Link to={doctorPath}>{study.doctorName ?? '-'}</Link>
                    </td>
                    <td>{studyType}</td>
                </tr>
            )
        });
    }

    renderPagination() {
        return (
            <Pagination totalCount={this.props.studiesList.totalCount}
                        currentOffset={this.props.studiesList.currentOffset}
                        limit={STUDIES_LIST_LIMIT}
                        switchPage={(offset) => this.props.loadStudiesListData(this.state.filterPeriod, this.state.sortColName + '.' + this.state.sortDirection, offset)}/>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        studiesList: state.commonStudiesList,
    }
}

const mapDispatchToProps = {
    loadStudiesListData: loadCommonStudiesListData,
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonStudiesList)
