import React from "react";
import axios from "axios";
import moment from "moment";
import {logout} from "../../../components/Auth";
import {Badge, Button, Jumbotron} from "react-bootstrap";

const SCOPE_MAP = {"upload-studies": "загрузка исследований"};
const STATUS_MAP = {
    "issued": <Badge variant="info">запрошено</Badge>,
    "granted": <Badge variant="primary">разрешено</Badge>,
    "declined": <Badge variant="secondary">отклонено</Badge>,
    "authorized": <Badge variant="primary">разрешено, получено</Badge>,
    "revoked": <Badge variant="warning">отозвано</Badge>,
    "expired": <Badge variant="danger">время действия истекло</Badge>,
};

class DesktopAppGrant extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            grant: null,
            message: null,
        };

        this.refreshGrant = this.refreshGrant.bind(this);
        this.renderQuestion = this.renderQuestion.bind(this);
        this.renderRevoke = this.renderRevoke.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
        this.accept = this.accept.bind(this);
        this.decline = this.decline.bind(this);
        this.revoke = this.revoke.bind(this);
    }

    componentDidMount() {
        this.refreshGrant();
    }

    refreshGrant() {
        axios.get("/api/1/assistant/desktop-app/grant/" + this.props.userCode, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                this.setState({
                    grant: response.data.grant,
                })
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    accept() {
        axios.patch("/api/1/assistant/desktop-app/grant/" + this.props.userCode + "/accept", {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(() => {
                this.refreshGrant();
                this.setState({message:"Эту страницу можно закрыть."});
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    decline() {
        axios.patch("/api/1/assistant/desktop-app/grant/" + this.props.userCode + "/decline", {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(() => {
                this.refreshGrant();
                this.setState({message:"Эту страницу можно закрыть."});
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    revoke() {
        axios.patch("/api/1/assistant/desktop-app/grant/" + this.props.userCode + "/revoke", {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(() => {
                this.refreshGrant();
                this.setState({message:"Эту страницу можно закрыть."});
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    renderQuestion() {
        if (this.state.grant.status !== 'issued') {
            return '';
        }

        return <>
            <p>Приложение запрашивает разрешения действовать от вашего имени.</p>
            <Button variant="primary" onClick={this.accept}>Разрешить</Button> <Button variant="danger" onClick={this.decline}>Запретить</Button>
        </>
    }

    renderRevoke() {
        if (this.state.grant.status !== 'granted' && this.state.grant.status !== 'authorized') {
            return '';
        }

        return <Button variant="warning" onClick={this.revoke}>Отозвать разрешение</Button>
    }

    renderMessage() {
        if (!this.state.message) {
            return '';
        }

        return <p>{this.state.message}</p>;
    }

    render() {
        if (!this.state.grant) {
            return <p>Загрузка...</p>
        }

        const permissions = this.state.grant.scope.split(" ").map((scope) => {
            return SCOPE_MAP[scope];
        });
        const status = STATUS_MAP[this.state.grant.status];
        const issuedAt = moment(this.state.grant.issuedAt).format("DD.MM.YYYY HH:MM")
        const expiresAt = moment(this.state.grant.expiresAt).format("DD.MM.YYYY HH:MM")
        const question = this.renderQuestion();
        const revoke = this.renderRevoke();
        const message = this.renderMessage();

        return <>
            <Jumbotron>
                <h1>Разрешение входа для приложения</h1>
                <p>Приложение: {this.state.grant.appVersion}</p>
                <p>Разрешения: {permissions.join(", ")}</p>
                <p>Статус: {status}</p>
                <p>Запрошено: {issuedAt}</p>
                <p>Действует до: {expiresAt}</p>
                <hr />
                {question}
                {revoke}
                {message}
            </Jumbotron>
        </>;
    }
}

export default DesktopAppGrant;
