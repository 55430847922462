import React from "react";
import "./Messenger.css";
import DialogList from "./DialogList";
import MessageList from "./MessageList";
import {Row} from "react-bootstrap";
import {getCentrifuge} from "../../../../components/Centrifuge";
import axios from "axios";
import {logout} from "../../../../components/Auth";

// thanks https://jsfiddle.net/bootstrapious/0z71hmax/
class Messenger extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogID: -1,
            dialogs: [],
            messages: [],
        };

        this.centrifuge = getCentrifuge();
        this.incomingMessageSound = new Audio('/sounds/incoming_message.ogg');
        this.incomingMessageSound.autoplay = true;
        this.incomingMessageSound.muted = true;

        this.chooseDialog = this.chooseDialog.bind(this);
        this.handleCentrifugeMessage = this.handleCentrifugeMessage.bind(this);
        this.loadDialogs = this.loadDialogs.bind(this);
        this.markMessagesRead = this.markMessagesRead.bind(this);
        this.addNewMessageToCurrentDialog = this.addNewMessageToCurrentDialog.bind(this);
        this.updateDialogLastMessage = this.updateDialogLastMessage.bind(this);
        this.substringLastMessageText = this.substringLastMessageText.bind(this);
        this.markDialogRead = this.markDialogRead.bind(this);
    }

    componentDidMount() {
        this.centrifugeSubscription = this.centrifuge.subscribe("$assistants_messages", this.handleCentrifugeMessage);
        this.centrifuge.connect();
        this.loadDialogs();
    }

    componentWillUnmount() {
        if (this.centrifugeSubscription) {
            this.centrifugeSubscription.unsubscribe();
        }

        this.centrifuge.disconnect();
    }

    loadDialogs() {
        axios.get("/api/1/assistant/messenger/dialogs", {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                this.setState({
                    dialogs: response.data.dialogs,
                })
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    handleCentrifugeMessage(centrifugeEvent) {
        const event = centrifugeEvent.data;

        if (event.type === 'new_message') {
            const message = event.data;

            if (message.dialogID === this.state.dialogID) {
                this.addNewMessageToCurrentDialog(message);
            }

            this.updateDialogLastMessage(message);

            if (!message.isAnswer) {
                this.incomingMessageSound.muted = false;
                this.incomingMessageSound.play();
            }
        }
    }

    addNewMessageToCurrentDialog(message) {
        this.setState((state) => {
            const messages = state.messages;

            messages.push(message);

            return {
                messages: messages,
            };
        });

        if (!message.isAnswer) {
            this.markMessagesRead(message.dialogID, [message.messageID])
        }
    }

    updateDialogLastMessage(message) {
        this.setState((state) => {
            let dialogs = state.dialogs;

            dialogs = dialogs.map((dialog) => {
                if (dialog.dialogID === message.dialogID) {
                    dialog.isAnswer = message.isAnswer;
                    dialog.isRead = message.isRead;
                    dialog.lastMessageCreatedAt = message.createdAt;
                    dialog.lastMessageText = this.substringLastMessageText(message.text);

                    // если данный диалог открыт - значит сообщение прочитано
                    if (!dialog.isAnswer && this.state.dialogID === dialog.dialogID) {
                        dialog.isRead = true;
                    }
                }

                return dialog;
            });

            dialogs.sort((a, b) => {
                if (a.lastMessageCreatedAt === null) {
                    return 1;
                }

                if (b.lastMessageCreatedAt === null) {
                    return -1;
                }

                return new Date(b.lastMessageCreatedAt) - new Date(a.lastMessageCreatedAt);
            });

            return {
                dialogs: dialogs,
            };
        });
    }

    substringLastMessageText(text) {
        if (text.length <= 65) {
            return text;
        }

        return text.slice(0, 66).trim() + '...';
    }

    chooseDialog(id) {
        this.setState({dialogID: id});
        this.loadDialogMessages(id);
    }

    loadDialogMessages(dialogID) {
        axios.get("/api/1/assistant/messenger/dialogs/" + dialogID + "/messages", {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                this.setState({
                    messages: response.data.messages.reverse(),
                })

                this.markMessagesRead(dialogID, response.data.messages.filter((message) => !message.isAnswer).map((message) => message.messageID));
                this.markDialogRead(dialogID);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            });
    }

    markMessagesRead(dialogID, messageIDs) {
        if (dialogID === -1 || messageIDs.length === 0) {
            return;
        }

        messageIDs = [Math.max.apply(null, messageIDs)];

        axios.patch("/api/1/assistant/messenger/dialogs/" + dialogID + "/messages/mark-read", {
            messageIDs: messageIDs,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        });
    }

    markDialogRead(dialogID) {
        this.setState((state) => {
            let dialogs = state.dialogs;

            dialogs = dialogs.map((dialog) => {
                if (dialog.dialogID === dialogID) {
                    dialog.isRead = true;
                }

                return dialog;
            });

            return {
                dialogs: dialogs,
            };
        });
    }

    render() {
        return (
            <div className="messenger">
                <Row className="rounded-lg overflow-hidden shadow">
                    <DialogList dialogID={this.state.dialogID}
                                chooseDialog={this.chooseDialog}
                                dialogs={this.state.dialogs}/>
                    <MessageList dialogID={this.state.dialogID}
                                 messages={this.state.messages}/>
                </Row>
            </div>
        );
    }
}

export default Messenger;
