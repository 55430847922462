import {combineReducers} from "redux";

export const showAdvReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DISPLAY_ADV':
            return true;
        case 'HIDE_ADV':
            return false;
        default:
            return state;
    }
};

export const advDataReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_ADV_DATA':
            return action.data;
        default:
            return state;
    }
};

export const doctorReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DOCTOR':
            return action.data;
        default:
            return state;
    }
};

export const patientsListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PATIENTS_LIST_DATA':
            return action.data;
        default:
            return state;
    }
};

export const sharedPatientsListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_SHARED_PATIENTS_LIST_DATA':
            return action.data;
        default:
            return state;
    }
};

export const currentPatientReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_CURRENT_PATIENT_DATA':
            return action.data;
        default:
            return state;
    }
}

export const patientStudiesListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PATIENT_STUDIES_LIST_DATA':
            return action.data;
        default:
            return state;
    }
}

export const settingsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_SETTINGS_DATA':
            return action.data;
        default:
            return state;
    }
}

export const doctorFriendsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DOCTOR_FRIENDS_DATA':
            return action.data;
        default:
            return state;
    }
}

export const patientSharesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PATIENT_SHARES_DATA':
            return action.data;
        default:
            return state;
    }
}

export const reducers = combineReducers({
    showAdv: showAdvReducer,
    advData: advDataReducer,
    doctor: doctorReducer,
    doctorFriends: doctorFriendsReducer,
    patientsList: patientsListReducer,
    sharedPatientsList: sharedPatientsListReducer,
    currentPatient: currentPatientReducer,
    patientStudiesList: patientStudiesListReducer,
    patientShares: patientSharesReducer,
    settings: settingsReducer,
});