import PatientsList from "./PatientsList";
import {loadPatientsInWorkList} from "../store/actions";
import {connect} from "react-redux";

const mapStateToProps = (state) => {
    return {
        patientsList: state.patientsInWorkList,
    }
}

export default connect(mapStateToProps, {
    loadPatientsList: loadPatientsInWorkList,
})(PatientsList)
