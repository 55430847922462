import React from "react";
import {NavLink, Route, Switch} from "react-router-dom";
import {Button, Col, Container, Image, InputGroup, Nav, Row, Tab} from "react-bootstrap";
import CreateApplicationForm from "./components/CreateApplicationForm";
import UpdateBannerForm from "./components/UpdateBannerForm";
import "easymde/dist/easymde.min.css";
import {connect} from "react-redux";
import DoctorPatientsList from "./components/DoctorPatientsList";
import PatientStudiesList from "./components/PatientStudiesList";
import NotFound from "../../components/NotFound";
import DoctorsList from "./components/DoctorsList";
import PatientsListInWork from "./components/PatientsListInWork";
import Search from "./components/Search";
import Messenger from "./components/Messenger/Messenger";
import CommonStudiesList from "./components/CommonStudiesList";
import {loadCounters, loadSettingsData} from "./store/actions";
import PatientsListForRestore from "./components/PatientsListForRestore";
import BrowserCompatibilityAlert from "../../components/BrowserCompatibilityAlert";
import SharedStudy from "../external/components/SharedStudy";
import DesktopAppGrant from "./components/DesktopAppGrant";

class AssistantArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTabKey: this.props.commonStudiesListEnabled ? 'studies' : 'doctors',
        };

        this.onTabChange = this.onTabChange.bind(this);
    }

    componentDidMount() {
        this.props.loadSettingsData();
        this.props.loadCounters();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.commonStudiesListEnabled !== prevProps.commonStudiesListEnabled) {
            this.setState({
                activeTabKey: this.props.commonStudiesListEnabled ? 'studies' : 'doctors',
            });
        }
    }

    render() {
        const menu = this.renderMenu();
        const pages = this.renderPages();

        return (
            <Container className="app">
                <BrowserCompatibilityAlert/>
                <Row>
                    <Col md={3}>
                        <Image className="logo" src="/images/logo.png" alt={process.env.REACT_APP_WEBSITE_NAME} fluid/>
                    </Col>
                    <Col md={8}>
                        <InputGroup className="search">
                            <Search/>
                        </InputGroup>
                    </Col>
                    <Col md={1}>
                        <p className="text-right logout">
                            <Button variant="dark" onClick={this.props.logout}>Выйти</Button>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        {menu}
                    </Col>
                    <Col md="9">
                        {pages}
                    </Col>
                </Row>
            </Container>
        )
    }

    renderMenu() {
        let items = [
            <Nav.Item key="home">
                <Nav.Link as={NavLink} exact activeClassName="active"
                          to="/assistant-area/">Главная</Nav.Link>
            </Nav.Item>,
            <Nav.Item key="create-study">
                <Nav.Link as={NavLink} exact activeClassName="active"
                          to="/assistant-area/applications/create">Создать
                    исследование</Nav.Link>
            </Nav.Item>
        ];

        if (this.props.chatEnabled) {
            items.push(
                <Nav.Item key="messenger">
                    <Nav.Link as={NavLink} exact activeClassName="active"
                              to="/assistant-area/messenger">Мессенджер</Nav.Link>
                </Nav.Item>
            );
        }

        items.push(
            <Nav.Item key="banner">
                <Nav.Link as={NavLink} exact activeClassName="active"
                          to="/assistant-area/banner/update">Настройка баннера</Nav.Link>
            </Nav.Item>
        );

        return <Nav variant="pills" className="flex-column">
            {items}
        </Nav>
    }

    renderPages() {
        const items = [
            <Route key="home" exact path="/assistant-area/">
                {this.renderMainTabs()}
            </Route>,
            <Route key="studies" exact
                   path="/assistant-area/doctors/:doctorID/patients/:patientID/studies"
                   children={({match}) => (
                       <PatientStudiesList doctorID={match.params.doctorID}
                                           patientID={match.params.patientID}/>
                   )}/>,
            <Route key="patients" exact
                   path="/assistant-area/doctors/:doctorID/patients"
                   children={({match}) => (
                       <DoctorPatientsList doctorID={match.params.doctorID}/>
                   )}>
            </Route>,
            <Route key="create-study" path="/assistant-area/applications/create/:doctorID?/:patientID?"
                   children={({match}) => (
                       <CreateApplicationForm doctorID={match.params.doctorID}
                                              patientID={match.params.patientID}/>
                   )}>
            </Route>
        ];

        if (this.props.chatEnabled) {
            items.push(
                <Route key="messenger" exact path="/assistant-area/messenger">
                    <Messenger/>
                </Route>
            );
        }

        if (this.props.desktopAppAuthEnabled) {
            items.push(
                <Route key="desktop-app-grant" exact path="/assistant-area/desktop-grant/:userCode" children={({match}) => (
                    <DesktopAppGrant userCode={match.params.userCode}/>
                )}/>
            );
        }

        items.push([
            <Route key="update-banner" exact path="/assistant-area/banner/update">
                <UpdateBannerForm/>
            </Route>,
            <Route key="not-found" path="/assistant-area/*">
                <NotFound pathToHome="/assistant-area/"/>
            </Route>
        ])

        return <Switch>
            {items}
        </Switch>
    }

    onTabChange(eventKey) {
        this.setState({
            activeTabKey: eventKey,
        });
    }

    renderMainTabs() {
        let patientsInWorkBadge = <span className="badge badge-light">0</span>;

        if (this.props.counters.patientsInWorkCount > 0) {
            patientsInWorkBadge =
                <span className="badge badge-primary">{this.props.counters.patientsInWorkCount}</span>;
        }

        let patientsForRestoreBadge = <span className="badge badge-light">0</span>;

        if (this.props.counters.patientsInWorkRestored > 0) {
            patientsForRestoreBadge =
                <span className="badge badge-warning">{this.props.counters.patientsInWorkRestored}</span>;
        }

        let nav = [
            <Nav.Item key="doctors">
                <Nav.Link eventKey="doctors" onSelect={this.onTabChange}>Клиенты (врачи/клиники) <span
                    className="badge badge-light">{this.props.counters.doctorsCount}</span></Nav.Link>
            </Nav.Item>,
            <Nav.Item key="patientsInWork">
                <Nav.Link eventKey="patientsInWork" onSelect={this.onTabChange}>Пациенты в
                    работе {patientsInWorkBadge}</Nav.Link>
            </Nav.Item>,
            <Nav.Item key="patientsForRestore">
                <Nav.Link eventKey="patientsForRestore" onSelect={this.onTabChange}>На
                    восстановление {patientsForRestoreBadge}</Nav.Link>
            </Nav.Item>,
        ];

        let tabs = [
            <Tab.Pane key="doctors" eventKey="doctors">
                <br/>
                <DoctorsList/>
            </Tab.Pane>,
            <Tab.Pane key="patientsInWork" eventKey="patientsInWork">
                <br/>
                <PatientsListInWork/>
            </Tab.Pane>,
            <Tab.Pane key="patientsForRestore" eventKey="patientsForRestore">
                <br/>
                <PatientsListForRestore/>
            </Tab.Pane>,
        ];

        if (this.props.commonStudiesListEnabled) {
            nav.unshift(<Nav.Item key="studies">
                <Nav.Link eventKey="studies" onSelect={this.onTabChange}>Исследования <span
                    className="badge badge-light">{this.props.counters.studiesCount}</span></Nav.Link>
            </Nav.Item>);
            tabs.unshift(<Tab.Pane key="studies" eventKey="studies">
                <br/>
                <CommonStudiesList/>
            </Tab.Pane>);
        }

        return <Tab.Container activeKey={this.state.activeTabKey}>
            <Nav variant="tabs">
                {nav}
            </Nav>
            <Tab.Content>
                {tabs}
            </Tab.Content>
        </Tab.Container>;
    }
}

const mapStateToProps = (state) => {
    return {
        commonStudiesListEnabled: state.settings.commonStudiesListEnabled,
        chatEnabled: state.settings.chatEnabled,
        desktopAppAuthEnabled: state.settings.desktopAppAuthEnabled,
        counters: state.counters,
    }
}

const mapDispatchToProps = {
    loadSettingsData: loadSettingsData,
    loadCounters: loadCounters,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssistantArea)
