import PatientsList from "./PatientsList";
import {loadPatientsForRestoreList} from "../store/actions";
import {connect} from "react-redux";

const mapStateToProps = (state) => {
    return {
        patientsList: state.patientsForRestoreList,
    }
}

export default connect(mapStateToProps, {
    loadPatientsList: loadPatientsForRestoreList,
})(PatientsList)
