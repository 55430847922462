import React from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {
    loadDropdownDoctorPatientsList,
    loadDropdownDoctorsListData
} from "../store/actions";
import {connect} from "react-redux";
import axios from "axios";
import {logout} from "../../../components/Auth";

class CopyStudyModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            doctorID: 0,
            patientID: 0,
            opened: false,
            shouldDuplicatePatient: false,
            selectedDoctor: [],
            selectedPatient: [],

            error: "",
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDoctorChange = this.handleDoctorChange.bind(this);
        this.handlePatientChange = this.handlePatientChange.bind(this);
        this.handleDuplicatePatient = this.handleDuplicatePatient.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (!prevState.opened && this.state.opened) {
            this.props.loadDoctorsListData();
        }
    }

    handleOpen() {
        this.setState({
            opened: true,
        })
    }

    handleClose() {
        this.setState({
            opened: false,
        });
    }

    handleDoctorChange(options) {
        if (options.length < 1) {
            this.setState({
                doctorID: 0,
                patientID: 0,
                selectedDoctor: [],
            })
            return;
        }

        const doctorID = options[0].doctorID;

        this.setState({
            doctorID: parseInt(doctorID),
            selectedDoctor: [options[0]],
            selectedPatient: [],
            patientID: 0, // hack for select first option
        })

        this.props.loadDoctorPatientsList(doctorID);
    }

    handlePatientChange(options) {
        let patientID = 0;
        let selectedPatient = [];

        if (options.length === 1) {
            patientID = options[0].patientID;
            selectedPatient = [options[0]];
        }

        this.setState({
            patientID: patientID,
            selectedPatient: selectedPatient,
        })
    }

    handleDuplicatePatient(e) {
        this.setState({
            shouldDuplicatePatient: e.target.checked,
        })
    }

    handleCopy() {
        const path = "/api/1/assistant/studies/" + this.props.studyID + "/copy"

        axios.post(path, {
            doctorID: this.state.doctorID,
            patientID: this.state.patientID,
            shouldDuplicatePatient: this.state.shouldDuplicatePatient,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            const patientID = response.data.patientID;
            window.location.href = '/assistant-area/doctors/' + this.state.doctorID + '/patients/' + patientID + '/studies';
        }).catch((error) => {
            if (!error.response) {
                console.log(error);
                return
            }

            this.setState({
                error: error.response.data.error,
            })

            if (error.response && error.response.status === 401) {
                logout();
                window.location.reload();
            }
        })
    }

    render() {
        const button = this.renderButton();

        if (!this.state.opened) {
            return button;
        }

        return <>
            {button}

            <Modal show={this.state.opened} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Копирование исследования</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleCopy}>
                        <Form.Group controlId="copyStudyDoctor">
                            <Typeahead
                                id="choose-doctor"
                                labelKey="name"
                                onChange={this.handleDoctorChange}
                                options={this.props.doctorsList}
                                placeholder="выберите клиента"
                                selected={this.state.selectedDoctor}
                            />
                        </Form.Group>
                        <Form.Group controlId="copyStudyPatient">
                            <Typeahead
                                id="choose-patient"
                                labelKey="name"
                                onChange={this.handlePatientChange}
                                options={this.props.doctorPatientsList}
                                placeholder="выберите пациента"
                                selected={this.state.selectedPatient}
                            />
                        </Form.Group>
                        <Form.Group controlId="copyStudyDuplicatePatient">
                            <Form.Check type="checkbox" checked={this.state.shouldDuplicatePatient} onChange={this.handleDuplicatePatient} label="В списке нет нужного пациента. Создать нового с полями как у текущего" />
                        </Form.Group>
                    </Form>
                    <Alert variant="danger" show={!!this.state.error}>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleCopy}>
                        Скопировать
                    </Button>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }

    renderButton() {
        return <>
            <Button variant="info" onClick={this.handleOpen} title="Копия">К</Button>
            &nbsp;
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        doctorsList: state.dropdownDoctorsList,
        doctorPatientsList: state.dropdownDoctorPatientsList,
    }
}

const mapDispatchToProps = {
    loadDoctorsListData: loadDropdownDoctorsListData,
    loadDoctorPatientsList: loadDropdownDoctorPatientsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyStudyModal);
