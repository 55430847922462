import React from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import moment from "moment";
import axios from "axios";
import {logout} from "../../../../components/Auth";

const STEP1 = 1;
const STEP2 = 2;
const STEP3 = 3;
const STEP4 = 4;

class Schedule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: STEP1,
            patientName: "",
            patientPhone: "",
            studyComment: "",
            scheduleDate: "",
            scheduleSlotUUID: "",
            scheduleSlotName: "",
            locationID: "",
            locationName: "",
            error: "",
        };

        this.switchStep1 = this.switchStep1.bind(this);
        this.switchStep2 = this.switchStep2.bind(this);
        this.switchStep3 = this.switchStep3.bind(this);
        this.setPatientName = this.setPatientName.bind(this);
        this.setPatientPhone = this.setPatientPhone.bind(this);
        this.setStudyComment = this.setStudyComment.bind(this);
        this.setScheduleDate = this.setScheduleDate.bind(this);
        this.setLocation = this.setLocation.bind(this);
        this.setScheduleSlot = this.setScheduleSlot.bind(this);
        this.schedule = this.schedule.bind(this);
    }

    setPatientName(name) {
        this.setState({patientName: name})
    }

    setPatientPhone(phone) {
        this.setState({patientPhone: phone})
    }

    setStudyComment(comment) {
        this.setState({studyComment: comment})
    }

    setScheduleDate(date) {
        this.setState({scheduleDate: date})
    }

    setLocation(id, name) {
        this.setState({
            locationID: id,
            locationName: name,
        })
    }

    setScheduleSlot(uuid, name) {
        this.setState({
            scheduleSlotUUID: uuid,
            scheduleSlotName: name,
        })
    }

    switchStep1() {
        this.setState({step: STEP1});
    }

    switchStep2() {
        this.setState({step: STEP2});
    }

    switchStep3() {
        this.setState({step: STEP3});
    }

    schedule() {
        const path = "/api/1/doctor/schedule/locations/" + this.state.locationID + "/" + this.state.scheduleDate + "/" + this.state.scheduleSlotUUID;

        axios.post(path, {
            patientName: this.state.patientName,
            patientPhone: this.state.patientPhone,
            studyComment: this.state.studyComment,
        })
            .then((response) => {
                this.setState({
                    step: STEP4,
                    patientName: "",
                    patientPhone: "",
                    studyComment: "",
                    scheduleDate: "",
                    scheduleSlotUUID: "",
                    scheduleSlotName: "",
                    locationID: "",
                    locationName: "",
                })
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }

                this.setState({error: error.response.data.error});
            })
    }

    render() {
        let step = <Step1 nextStep={this.switchStep2}
                          patientName={this.state.patientName} setPatientName={this.setPatientName}
                          patientPhone={this.state.patientPhone} setPatientPhone={this.setPatientPhone}
                          studyComment={this.state.studyComment} setStudyComment={this.setStudyComment} />;
        if (this.state.step === STEP2) {
            const minDate = this.props.scheduleMinDate;
            const maxDate =  this.props.scheduleMaxDate;
            step = <Step2 prevStep={this.switchStep1}
                          nextStep={this.switchStep3}
                          scheduleDate={this.state.scheduleDate} setScheduleDate={this.setScheduleDate} minDate={minDate} maxDate={maxDate}
                          locationID={this.state.locationID} setLocation={this.setLocation}
                          scheduleSlotUUID={this.state.scheduleSlotUUID} setScheduleSlot={this.setScheduleSlot} />;
        }
        if (this.state.step === STEP3) {
            step = <Step3 prevStep={this.switchStep2} schedule={this.schedule}
                          patientName={this.state.patientName}
                          patientPhone={this.state.patientPhone}
                          studyComment={this.state.studyComment}
                          scheduleDate={this.state.scheduleDate}
                          locationName={this.state.locationName}
                          scheduleSlotName={this.state.scheduleSlotName}
                          error={this.state.error} />;
        }
        if (this.state.step === STEP4) {
            step = <Step4 nextStep={this.switchStep1} />
        }

        return <>
            <h2>Онлайн-запись пациента на исследование</h2>
            {step}
        </>;
    }
}

export default Schedule;