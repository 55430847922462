import {Link} from "react-router-dom";
import React from "react";

class NotFound extends React.Component {
    render() {
        return (
            <>
                <h1>Запрашиваемая страница не найдена</h1>
                <p>Перейти <Link to={this.props.pathToHome}>на главную</Link></p>
            </>
        )
    }
}

export default NotFound;
