import React from "react";
import BrowserCompatibilityAlert from "./BrowserCompatibilityAlert";
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import axios from "axios";

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            password: '',
            passwordRepeat: '',
            disableSendBtn: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordRepeat = this.handleChangePasswordRepeat.bind(this);
    }

    handleChangePassword(event) {
        this.setState({password: event.target.value})
    }

    handleChangePasswordRepeat(event) {
        this.setState({passwordRepeat: event.target.value})
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return
        }

        if (this.state.password !== this.state.passwordRepeat) {
            this.setState({error: 'Повторный пароль не совпадает'})

            return
        }

        this.setState({disableSendBtn: true});

        axios.put('/api/1/auth/password/reset', {
            token: this.props.resetToken,
            password: this.state.password,
            repeat_password: this.state.passwordRepeat,
        }).then((response) => {
            this.setState({
                disableSendBtn: false,
                success: 'Пароль успешно установлен. Войдите с новым паролем',
            }, () => setTimeout(() => {
                window.location.href = '/';
            }, 3000));
        }).catch((error) => {
            let err_msg = 'Произошла неизвестная ошибка';

            console.log('auth/resetPassword', error);

            if (error.hasOwnProperty('response') && error.response.status !== 200) {
                switch (error.response.status) {
                    case 500:
                        err_msg = 'Произошла ошибка сервера. Повторите запрос';
                        break;
                    case 400:
                        err_msg = 'Невалидный запрос';
                        if (error.response.data.error !== 'invalid_request') {
                            err_msg = error.response.data.error;
                        }
                        break;
                    default:
                        err_msg = 'Неизвестный код ошибки: ' + error.response.status;
                        break;
                }
            }

            this.setState({error: err_msg, disableSendBtn: false});
        });
    }

    render() {
        let error = '';
        if (this.state.error) {
            error = <p className="text-danger">{this.state.error}</p>
        }

        return <Container>
            <BrowserCompatibilityAlert/>
            <Row>
                <Col md={{offset: 3, span: 6}}>
                    <h1>Восстановление пароля</h1>
                    <h4>Придумайте новый пароль</h4>
                    Пароль должен содержать:
                    <ul>
                        <li>не меньше 8 символов</li>
                        <li>маленькие буквы кириллицы или латиницы</li>
                        <li>большие буквы кириллицы или латиницы</li>
                        <li>цифры</li>
                    </ul>
                    Пароль не должен быть простым (например: 12345678 или qwerty123).
                    <hr/>
                </Col>
            </Row>
            <Row>
                <Col md={{offset: 3, span: 6}}>
                    {error}
                    <Form onSubmit={this.handleSubmit}>
                        <label htmlFor="resetPasswordFormPassword">Пароль:</label>
                        <InputGroup className="mb-3">
                            <FormControl onChange={this.handleChangePassword} required type="password"
                                         minLength="8" placeholder="secret"
                                         id="resetPasswordFormPassword"/>
                        </InputGroup>
                        <label htmlFor="resetPasswordFormPasswordRepeat">Повторите пароль:</label>
                        <InputGroup className="mb-3">
                            <FormControl onChange={this.handleChangePasswordRepeat} required type="password"
                                         minLength="8" placeholder="secret"
                                         id="resetPasswordFormPasswordRepeat"/>
                        </InputGroup>

                        <Button variant="primary" type="submit" disabled={this.state.disableSendBtn}>
                            Сохранить новый пароль
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>;
    }
}

export default ResetPassword;
